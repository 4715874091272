import {
  FetchUsersFailureAction,
  FetchUsersRequestAction,
  FetchUsersSuccessAction,
  UsersState,
} from '../types'

export const fetchUsersRequest = (
  state: UsersState,
  action: FetchUsersRequestAction,
): UsersState => {
  return {
    ...state,
    usersLoading: true,
  }
}

export const fetchUsersSuccess = (
  state: UsersState,
  action: FetchUsersSuccessAction,
): UsersState => {
  return {
    ...state,
    users: action.payload,
    usersLoading: false,
  }
}

export const fetchUsersFailure = (
  state: UsersState,
  action: FetchUsersFailureAction,
): UsersState => {
  return {
    ...state,
    error: action.payload,
    usersLoading: false,
  }
}
