import { Partner } from './Partner'
import { KeyValue } from './Utils'

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INVITED = 'INVITED',
}

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  lastLogin: Date
  permissions: KeyValue<string[]>
  externalIds: KeyValue<string>
  status: UserStatus
}

export interface UserDialog {
  action?: UserDialogAction
  user?: User
  executingAction?: boolean
  partner?: Partner
  partners?: Partner[]
}

export enum UserDialogAction {
  SUSPEND = 'Suspend',
  UNSUSPEND = 'Unsuspend',
  REMOVE = 'Remove',
  RESEND_INVITE = 'Resend_invite',
  REMOVE_ALL = 'Remove_all',
  SUSPEND_ALL = 'Suspend_all',
}
