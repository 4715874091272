import {
  ApplicationsState,
  FetchPermissionsFailureAction,
  FetchPermissionsRequestAction,
  FetchPermissionsSuccessAction,
} from '../types'

export const fetchPermissionsRequest = (
  state: ApplicationsState,
  action: FetchPermissionsRequestAction,
): ApplicationsState => {
  return {
    ...state,
    loadingPermissions: true,
  }
}

export const fetchPermissionsSuccess = (
  state: ApplicationsState,
  action: FetchPermissionsSuccessAction,
): ApplicationsState => {
  return {
    ...state,
    permissions: action.permissions,
    loadingPermissions: false,
  }
}

export const fetchPermissionsFailure = (
  state: ApplicationsState,
  action: FetchPermissionsFailureAction,
): ApplicationsState => {
  return {
    ...state,
    error: action.error,
    loadingPermissions: false,
  }
}
