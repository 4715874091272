import {
  PartnersState,
  SuspendRoleFailureAction,
  SuspendRoleRequestAction,
  SuspendRoleSuccessAction,
} from '../types'

export const suspendRoleRequest = (
  state: PartnersState,
  action: SuspendRoleRequestAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: true,
    },
  }
}

export const suspendRoleSuccess = (
  state: PartnersState,
  action: SuspendRoleSuccessAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: false,
    },
  }
}

export const suspendRoleFailure = (
  state: PartnersState,
  action: SuspendRoleFailureAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: false,
    },
  }
}
