import { User } from '../../../shared/model/User'
import { AppDispatch } from '../../store'
import { AppThunk } from '../../utils'
import { createUsers } from './helpers'
import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  UsersActionTypes,
} from '../types'
import { Partner } from '../../../shared/model/Partner'

export function fetchUsersRequest(): UsersActionTypes {
  return {
    type: FETCH_USERS_REQUEST,
  }
}

export function fetchUsersSuccess(users: User[]): UsersActionTypes {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  }
}

export function fetchUsersFailure(error: Error): UsersActionTypes {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  }
}

export const fetchUsers =
  ({
    withLoading = true,
    forPartner = undefined,
  }: {
    withLoading?: boolean
    forPartner?: Partner
  } = {}): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (withLoading) dispatch(fetchUsersRequest())
    try {
      const users = await createUsers(dispatch as AppDispatch, getState, forPartner)
      dispatch(fetchUsersSuccess(users))
    } catch (error) {
      dispatch(fetchUsersFailure(error as Error))
    }
  }
