import { Permissions } from './Permission'

export interface KeyValue<ValueType> {
  [key: string]: ValueType
}

export interface Error {
  code?: number
  message: string
}

export interface ToasterMessage {
  message: JSX.Element | string
  severity: ToastSeverity
}

export enum ToastSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type RetryOptions = {
  retryCondition: (input: any) => boolean
  options: { retries: number; minTimeout: number }
}

export interface AdminPermission {
  partnerId: string
  permissions: Permissions
  assignedPermissions: Permissions
  canAssignPermissions: Permissions
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}
