import React from 'react'
import { useTranslation } from 'react-i18next'
import { supportedLanguages } from '../../assets/lang/langSupport'
import { getCurrentLanguageCode, getSupportedLanguages } from '../../i18n'
import { Button, ButtonGroup } from '@mui/material'
import theme from '../../hoc/layout/theme'

const Settings: React.FC = (props) => {
  const { i18n } = useTranslation()
  const handleLanguageChange = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await i18n.changeLanguage(event.currentTarget.value)
  }

  //TODO: Rewrite the selected language styling to be more concice.
  const buttons = supportedLanguages
    .filter((l) => getSupportedLanguages().includes(l.code))
    .map((language) => (
      <Button
        value={language.code}
        onClick={handleLanguageChange}
        sx={{
          height: '38px',
          backgroundColor:
            getCurrentLanguageCode() === language.code.toUpperCase()
              ? theme.palette.medium.main
              : theme.palette.primary.main,
          color: getCurrentLanguageCode() === language.code.toUpperCase() ? 'white' : 'black',
        }}
      >
        {language.name} ({language.code.toUpperCase()})
      </Button>
    ))
  return (
    <>
      <div>
        <ButtonGroup orientation='vertical' variant='menuButton' fullWidth>
          {buttons}
        </ButtonGroup>
      </div>
    </>
  )
}

export default Settings
