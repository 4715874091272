import {
  PartnersState,
  UpdatePartnerRequestAction,
  UpdatePartnerSuccessAction,
  UpdatePartnerFailureAction,
} from '../types'

export const updatePartnerRequest = (
  state: PartnersState,
  action: UpdatePartnerRequestAction,
): PartnersState => {
  return {
    ...state,
    updatePartnerLoading: true,
  }
}

export const updatePartnerSuccess = (
  state: PartnersState,
  action: UpdatePartnerSuccessAction,
): PartnersState => {
  return {
    ...state,
    updatePartnerLoading: false,
  }
}

export const updatePartnerFailure = (
  state: PartnersState,
  action: UpdatePartnerFailureAction,
): PartnersState => {
  return {
    ...state,
    error: action.error,
    updatePartnerLoading: false,
  }
}
