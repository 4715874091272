import { Button, CircularProgress, Dialog, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Application, APPLICATIONS } from '../../shared/model/Application'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Logo from '../Logo/Logo'
import axiosInstance from '../../axios'
import { useIsAuthenticated } from '@azure/msal-react'

const InviteRedirect = () => {
  const [loading, setLoading] = useState(false)
  const [appAccess, setAppAccess] = useState<Application[]>([])
  const isAuthenticated = useIsAuthenticated()
  const { t } = useTranslation()

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true)
      axiosInstance
        .get('/role/roles/users/me/applications')
        .then((response) => response.data)
        .then((data) => setAppAccess(data))
        .then(() => setLoading(false))
    }
  }, [isAuthenticated])

  const appsInvitedTo = useMemo(
    () => APPLICATIONS.filter((app) => appAccess.includes(app.id)),
    [appAccess],
  )

  return (
    <Dialog open={true} fullScreen>
      <Grid container justifyContent='flex-end' style={{ padding: '0.5em' }}>
        <Grid item>
          <LanguageSelector />
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        spacing={1}
        justifyContent='center'
        alignItems='center'
        style={{ height: '100%' }}
      >
        <Grid item style={{ marginBottom: '2em' }}>
          <Logo />
        </Grid>
        {isAuthenticated ? (
          <>
            <Grid item>
              <Typography align='center' variant='h4'>
                {t('invitation_completed')}
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              justifyContent='center'
              alignItems='center'
              style={{ width: '50%' }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {appsInvitedTo.flatMap((app) =>
                    app.webURLs.map((site, i) => {
                      return (
                        <Grid item key={`${app.id}-${i}`} xs={6}>
                          <Button fullWidth color='primary' variant='contained' href={site.url}>
                            {t('go_to', { destination: site.siteName })}
                          </Button>
                        </Grid>
                      )
                    }),
                  )}
                </>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Typography variant='h4'>{t('invite_aborted')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='subtitle1'>{t('invite_aborted_description')}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  )
}

export default InviteRedirect
