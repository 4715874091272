import {
  AuthState,
  AuthActionTypes,
  FetchAdminPermissionsRequestAction,
  FetchAdminPermissionsSuccessAction,
  FetchAdminPermissionsFailureAction,
  FETCH_ADMIN_PERMISSIONS_REQUEST,
  FETCH_ADMIN_PERMISSIONS_SUCCESS,
  FETCH_ADMIN_PERMISSIONS_FAILURE,
} from './types'

export const initialState: AuthState = {
  adminPermissions: [],
  loadingAdminPermissions: false,
  adminPermissionsError: undefined,
}

const fetchAdminPermissionsRequest = (
  state: AuthState,
  action: FetchAdminPermissionsRequestAction,
): AuthState => {
  return {
    ...state,
    loadingAdminPermissions: true,
  }
}

const fetchAdminPermissionsSuccess = (
  state: AuthState,
  action: FetchAdminPermissionsSuccessAction,
): AuthState => {
  return {
    ...state,
    loadingAdminPermissions: false,
    adminPermissions: action.permissions,
  }
}

const fetchAdminPermissionsFailure = (
  state: AuthState,
  action: FetchAdminPermissionsFailureAction,
): AuthState => {
  return {
    ...state,
    loadingAdminPermissions: false,
  }
}

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case FETCH_ADMIN_PERMISSIONS_REQUEST:
      return fetchAdminPermissionsRequest(state, action)
    case FETCH_ADMIN_PERMISSIONS_SUCCESS:
      return fetchAdminPermissionsSuccess(state, action)
    case FETCH_ADMIN_PERMISSIONS_FAILURE:
      return fetchAdminPermissionsFailure(state, action)

    default:
      return state
  }
}
