import {
  PartnersState,
  RemoveRoleFromPartnerRequestAction,
  RemoveRoleFromPartnerSuccessAction,
  RemoveRoleFromPartnerFailureAction,
} from '../types'

export const removeRoleFromPartnerRequest = (
  state: PartnersState,
  action: RemoveRoleFromPartnerRequestAction,
): PartnersState => {
  return {
    ...state,
  }
}
export const removeRoleFromPartnerSuccess = (
  state: PartnersState,
  action: RemoveRoleFromPartnerSuccessAction,
): PartnersState => {
  return {
    ...state,
    ...removeRoleFromPartnerLocally(state, action),
  }
}
export const removeRoleFromPartnerFailure = (
  state: PartnersState,
  action: RemoveRoleFromPartnerFailureAction,
): PartnersState => {
  return { ...state }
}

const removeRoleFromPartnerLocally = (
  state: PartnersState,
  action: RemoveRoleFromPartnerSuccessAction,
): Partial<PartnersState> => {
  const statePartner = state.partner
  const partnerId = action.partnerId
  const userId = action.userId
  const partners = state.partners
  const partner = partners.find((p) => p.id === partnerId)
  const updatedMemberList = partner!.roles.filter((role) => role.userId !== userId)
  const newPartner = { ...partner!, roles: updatedMemberList }

  return {
    ...state,
    partner: statePartner?.id === partnerId ? newPartner : statePartner,
    partners: [...partners.filter((p) => p.id !== partnerId), newPartner],
  }
}
