import { AdminPermission, Error } from '../../shared/model/Utils'
import { Error as ThermiaError } from '../../shared/model/Utils'

export const FETCH_ADMIN_PERMISSIONS_REQUEST = 'FETCH_ADMIN_PERMISSIONS_REQUEST'
export const FETCH_ADMIN_PERMISSIONS_SUCCESS = 'FETCH_ADMIN_PERMISSIONS_SUCCESS'
export const FETCH_ADMIN_PERMISSIONS_FAILURE = 'FETCH_ADMIN_PERMISSIONS_FAILURE'

export type AssumedByUser = {
  id: string
  name: string
}
export interface AuthState {
  adminPermissions: AdminPermission[]
  loadingAdminPermissions: boolean
  adminPermissionsError: Error | undefined
}

export interface Config {
  b2c: {
    apiScopes: string
    autorities: string
    clientId: string
    policies: {
      signUpSignIn: string
      forgotPassword: string
      profileEdit: string
      signUp: string
      magicLink: string
    }
    knownAuthorities: string[]
  }
}

export interface AuthError extends ThermiaError {
  type: AuthErrorType
}

export enum AuthErrorType {
  INITIALIZATION = 'INITIALIZATION',
  AUTHENTICATION = 'AUTHENTICATION',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}
export interface FetchAdminPermissionsSuccessAction {
  type: typeof FETCH_ADMIN_PERMISSIONS_SUCCESS
  permissions: AdminPermission[]
}

export interface FetchAdminPermissionsRequestAction {
  type: typeof FETCH_ADMIN_PERMISSIONS_REQUEST
}

export interface FetchAdminPermissionsFailureAction {
  type: typeof FETCH_ADMIN_PERMISSIONS_FAILURE
  error: Error
}

export type AuthActionTypes =
  | FetchAdminPermissionsRequestAction
  | FetchAdminPermissionsSuccessAction
  | FetchAdminPermissionsFailureAction
