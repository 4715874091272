import { ToasterMessage } from '../../shared/model/Utils'

export const ADD_TOASTER = 'ADD_TOASTER'
export const REMOVE_TOASTER = 'REMOVE_TOASTER'

export interface UIState {
  toasterMessage: ToasterMessage | undefined
}

export interface AddToasterAction {
  type: typeof ADD_TOASTER
  payload: ToasterMessage
}

export interface RemoveToasterAction {
  type: typeof REMOVE_TOASTER
}

export type UIActionTypes = AddToasterAction | RemoveToasterAction
