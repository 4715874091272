import { Button, Divider, Grid, Popover, PopoverProps, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'
import { useProfile } from '../../hooks/useProfile'

const gridStyle = {
  margin: '0px',
  padding: '0px',
  paddingLeft: '0px',
  paddingTop: '0px',
  maxWidth: '100%',
}

const headerGridStyle = {
  margin: '0px',
  padding: '0px',
  paddingLeft: '0px',
  paddingTop: '10px',
  maxWidth: '100%',
  minHeight: '56px',
}

interface ProfileMenuProps {
  id: string
  anchorEl: PopoverProps['anchorEl']
  onClose: PopoverProps['onClose']
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ id, anchorEl, onClose }) => {
  const { t } = useTranslation()
  const profile = useProfile()
  const auth = useAuth()

  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          width: '287px',
          maxHeight: '224px',
          background: '#EDF3FA',
          padding: '10px 0px 0px 0px',
        },
      }}
    >
      <Grid container spacing={0} direction='column' sx={gridStyle}>
        <Grid item xs={12} sx={headerGridStyle}>
          <Typography
            align='left'
            noWrap
            padding='0px 30px'
            sx={{ fontWeight: 'bolder', fontSize: '1rem' }}
          >
            {profile.firstName} {profile.lastName}
            {profile.assumedBy.name && (
              <Typography variant='subtitle2' color='#000000'>
                {t('as')} {profile.assumedBy.name}
              </Typography>
            )}
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              pt: 0,
              pb: 0,
              padding: '0px 30px',
              width: '287px',
              overflow: 'hidden',
            }}
          >
            <Typography variant='subtitle2' color='textSecondary' align='left' noWrap>
              {profile.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <Divider variant='fullWidth' sx={{ margin: '10px 30px 0px 30px' }} />
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <Button variant='menuButton' onClick={auth.editProfile}>
            {t('Edit profile')}
          </Button>
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <Button variant='menuButton' onClick={auth.changePassword}>
            {t('Change password')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant='menuButton'
            color='secondary'
            sx={{ marginTop: '10px', height: '65px' }}
            onClick={auth.logout}
          >
            {t('Sign out')}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default ProfileMenu
