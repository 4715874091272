import { Partner, Role } from '../../shared/model/Partner'
import { User, UserStatus } from '../../shared/model/User'
import { AdminPermission } from '../../shared/model/Utils'

export const userStatus = (userStatus?: string[], partner?: Partner) => {
  if (!userStatus || userStatus.length === 0) {
    return () => true
  }
  if (partner) {
    return function (user: User) {
      const role = getRole(user.id, partner)
      if (!role) return false

      let hasFilteredStatus = false

      for (var status of userStatus) {
        status === UserStatus.SUSPENDED
          ? (hasFilteredStatus ||= isRoleSuspended(role))
          : (hasFilteredStatus ||= status === user.status && !isRoleSuspended(role))
      }

      return hasFilteredStatus
    }
  }
  return function (user: User) {
    if (userStatus.length === 0) return true
    return userStatus.some((status) => status === user.status)
  }
}

export const getRole = (userId: User['id'], partner: Partner | undefined): Role | undefined => {
  const role = partner?.roles.find((role) => role.userId === userId)
  return role
}

export const isRoleSuspended = (role: Role): boolean => {
  return role.entitlements.some((ent) => ent.permissions?.includes('suspended'))
}

export const shouldSeeEditbutton = (
  user: User,
  ciamAdminPermissions?: string[],
  partner?: Partner,
) => {
  //If logged in user had permissions to suspend and the user is suspended or active the dots should show
  //OR
  //If logged in user has permsissions to invite user and user has status invited then dots should show
  return !!ciamAdminPermissions
    ? ((ciamAdminPermissions.includes('users.suspend') ||
        ciamAdminPermissions.includes('group.suspend')) &&
        userStatus([UserStatus.ACTIVE, UserStatus.SUSPENDED], partner)(user)) ||
        (ciamAdminPermissions.includes('users.invite') &&
          userStatus([UserStatus.INVITED], partner)(user))
    : false
}

export const getAdminCiamPermissionsForPartner = (
  adminPermissions: AdminPermission[],
  partner: Partner | undefined,
) => {
  return !!partner
    ? adminPermissions.find((perm) => perm.partnerId === partner?.id)?.assignedPermissions[
        'ciam'
      ] ?? []
    : []
}
