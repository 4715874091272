import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from './msalConfig'
import { getConfig } from './utils'

const appConfig = getConfig()

//CHANGE URL based on enviroment
const instance: AxiosInstance = axios.create({
  // baseURL: `https://api-nonprod.thermia.com/api/...`,
  baseURL: appConfig.baseUrl,
})

instance.interceptors.request.use(async (config) => {
  const accessToken = await getAccessToken()
  config.headers.Authorization = `Bearer ${accessToken}`

  return config
})

export default instance
