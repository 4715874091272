import { SalesDivision } from '../../shared/model/Application'
import { ApplicationPermissions } from '../../shared/model/Permission'
import { Error } from '../../shared/model/Utils'

export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST'
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS'
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE'

export const FETCH_SALES_DIVISIONS_REQUEST = 'FETCH_SALES_DIVISIONS_REQUEST'
export const FETCH_SALES_DIVISIONS_SUCCESS = 'FETCH_SALES_DIVISIONS_SUCCESS'
export const FETCH_SALES_DIVISIONS_FAILURE = 'FETCH_SALES_DIVISIONS_FAILURE'

export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST'
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS'
export const CREATE_PARTNER_FAILURE = 'CREATE_PARTNER_FAILURE'

export interface ApplicationsState {
  error: Error | undefined
  loading: boolean
  salesDivisions: SalesDivision[]
  loadingSalesDivisions: boolean
  salesDivisionsFetched: boolean
  permissions: ApplicationPermissions
  loadingPermissions: boolean
  creatingGroupLoading: boolean
  creatingPartnerLoading: boolean
  createGroupError: Error | undefined
  createPartnerError: Error | undefined
}

export interface FetchPermissionsRequestAction {
  type: typeof FETCH_PERMISSIONS_REQUEST
}

export interface FetchPermissionsSuccessAction {
  type: typeof FETCH_PERMISSIONS_SUCCESS
  permissions: ApplicationPermissions
}

export interface FetchPermissionsFailureAction {
  type: typeof FETCH_PERMISSIONS_FAILURE
  error: Error
}

export interface FetchSalesDivisionsRequestAction {
  type: typeof FETCH_SALES_DIVISIONS_REQUEST
}

export interface FetchSalesDivisionsSuccessAction {
  type: typeof FETCH_SALES_DIVISIONS_SUCCESS
  salesDivisions: SalesDivision[]
}

export interface FetchSalesDivisionsFailureAction {
  type: typeof FETCH_SALES_DIVISIONS_FAILURE
  error: Error
}

export interface CreatePartnerRequestAction {
  type: typeof CREATE_PARTNER_REQUEST
}

export interface CreatePartnerSuccessAction {
  type: typeof CREATE_PARTNER_SUCCESS
}

export interface CreatePartnerFailureAction {
  type: typeof CREATE_PARTNER_FAILURE
  error: Error
}

export type ApplicationsActionTypes =
  | FetchPermissionsRequestAction
  | FetchPermissionsSuccessAction
  | FetchPermissionsFailureAction
  | FetchSalesDivisionsRequestAction
  | FetchSalesDivisionsSuccessAction
  | FetchSalesDivisionsFailureAction
  | CreatePartnerRequestAction
  | CreatePartnerSuccessAction
  | CreatePartnerFailureAction
