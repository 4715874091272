import {
  ApplicationsState,
  CreatePartnerFailureAction,
  CreatePartnerRequestAction,
  CreatePartnerSuccessAction,
} from '../types'

export const createPartnerRequest = (
  state: ApplicationsState,
  action: CreatePartnerRequestAction,
): ApplicationsState => {
  return {
    ...state,
    creatingPartnerLoading: true,
  }
}

export const createPartnerSuccess = (
  state: ApplicationsState,
  action: CreatePartnerSuccessAction,
): ApplicationsState => {
  return {
    ...state,
    creatingPartnerLoading: false,
  }
}

export const createPartnerFailure = (
  state: ApplicationsState,
  action: CreatePartnerFailureAction,
): ApplicationsState => {
  return {
    ...state,
    createPartnerError: action.error,
    creatingPartnerLoading: false,
  }
}
