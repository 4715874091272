import {
  AddPartnerModalOpenAction,
  AddUserToPartnerModalAction,
  ADD_PARTNER_MODAL_OPEN,
  ADD_USER_TO_PARTNER_FAILURE,
  ADD_USER_TO_PARTNER_MODAL,
  ADD_USER_TO_PARTNER_REQUEST,
  ADD_USER_TO_PARTNER_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNER_FAILURE,
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_ROLE_FAILURE,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  PartnerActionTypes,
  PartnersState,
  SelectPartnerAction,
  SELECT_PARTNER,
  ResetPartnerAction,
  RESET_PARTNER,
  SetPartnerToSuspendAction,
  SET_PARTNER_TO_SUSPEND,
  SUSPEND_PARTNER_FAILURE,
  SUSPEND_PARTNER_REQUEST,
  SUSPEND_PARTNER_SUCCESS,
  SUSPEND_ROLE_FAILURE,
  SUSPEND_ROLE_REQUEST,
  SUSPEND_ROLE_SUCCESS,
  UNSUSPEND_PARTNER_FAILURE,
  UNSUSPEND_PARTNER_REQUEST,
  UNSUSPEND_PARTNER_SUCCESS,
  UNSUSPEND_ROLE_FAILURE,
  UNSUSPEND_ROLE_REQUEST,
  UNSUSPEND_ROLE_SUCCESS,
  SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE,
  SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS,
  SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST,
  UpdatePartnerModalOpenAction,
  UPDATE_PARTNER_FAILURE,
  UPDATE_PARTNER_MODAL_OPEN,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_ROLE_ENTITLEMENTS_FAILURE,
  UPDATE_ROLE_ENTITLEMENTS_REQUEST,
  UPDATE_ROLE_ENTITLEMENTS_SUCCESS,
  REMOVE_ROLE_FROM_PARTNER_REQUEST,
  REMOVE_ROLE_FROM_PARTNER_SUCCESS,
  REMOVE_ROLE_FROM_PARTNER_FAILURE,
  REMOVE_USER_FROM_ALL_PARTNERS_REQUEST,
  REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS,
  REMOVE_USER_FROM_ALL_PARTNERS_FAILURE,
} from './types'

import {
  addUserToPartnerFailure,
  addUserToPartnerRequest,
  addUserToPartnerSuccess,
} from './addUserToPartner/reducers'
import {
  fetchPartnerFailure,
  fetchPartnerRequest,
  fetchPartnerSuccess,
} from './fetchPartner/reducers'
import {
  fetchPartnersFailure,
  fetchPartnersRequest,
  fetchPartnersSuccess,
} from './fetchPartners/reducers'
import { fetchRoleFailure, fetchRoleRequest, fetchRoleSuccess } from './fetchRole/reducers'
import {
  suspendPartnerFailure,
  suspendPartnerRequest,
  suspendPartnerSuccess,
} from './suspendPartner/reducers'
import { suspendRoleFailure, suspendRoleRequest, suspendRoleSuccess } from './suspendRole/reducers'
import {
  unsuspendPartnerFailure,
  unsuspendPartnerRequest,
  unsuspendPartnerSuccess,
} from './unsuspendPartner/reducers'
import {
  unsuspendRoleFailure,
  unsuspendRoleRequest,
  unsuspendRoleSuccess,
} from './unsuspendRole/reducers'
import {
  updatePartnerFailure,
  updatePartnerRequest,
  updatePartnerSuccess,
} from './updatePartner/reducers'
import {
  updateRoleEntitlementsFailure,
  updateRoleEntitlementsRequest,
  updateRoleEntitlementsSuccess,
} from './updateRoleEntitlements/reducers'

import {
  suspendUserFromAllPartnersRequest,
  suspendUserFromAllPartnersFailure,
  suspendUserFromAllPartnersSuccess,
} from './suspendUserFromAllPartners/reducers'
import {
  removeRoleFromPartnerFailure,
  removeRoleFromPartnerRequest,
  removeRoleFromPartnerSuccess,
} from './removeRoleFromPartner/reducers'
import {
  removeUserFromAllPartnersFailure,
  removeUserFromAllPartnersRequest,
  removeUserFromAllPartnersSuccess,
} from './removeUserFromAllPartners/reducers'

export const initialState: PartnersState = {
  partner: undefined,
  partners: [],
  loadingPartners: false,
  loadingPartner: false,
  refreshingPartners: false,
  partnersFetched: false,
  addUserToPartnerModal: false,
  addUserToPartnerLoading: false,
  error: undefined,
  addUserToPartnerError: undefined,
  addPartnerModalOpen: false,
  updatePartnerModalOpen: false,
  updatePartnerLoading: false,
  partnerToSuspend: undefined,
  updateUserPermissionLoading: false,
  updateUserAdminStatusLoading: false,
  partnerStatusLoading: false,
  role: {
    roleSuspendLoading: false,
    roleUnsuspendLoading: false,
  },
}

const addUserToPartnerModal = (
  state: PartnersState,
  action: AddUserToPartnerModalAction,
): PartnersState => {
  return {
    ...state,
    addUserToPartnerModal: action.payload,
    addUserToPartnerError: undefined,
  }
}

const selectPartner = (state: PartnersState, action: SelectPartnerAction): PartnersState => {
  return {
    ...state,
  }
}

const resetPartner = (state: PartnersState, action: ResetPartnerAction): PartnersState => {
  return {
    ...state,
    partner: undefined,
  }
}

const addPartnerModalOpen = (
  state: PartnersState,
  action: AddPartnerModalOpenAction,
): PartnersState => {
  return {
    ...state,
    addPartnerModalOpen: action.open,
  }
}

const updatePartnerModalOpen = (
  state: PartnersState,
  action: UpdatePartnerModalOpenAction,
): PartnersState => {
  return {
    ...state,
    updatePartnerModalOpen: action.open,
  }
}

const setPartnerToSuspend = (
  state: PartnersState,
  action: SetPartnerToSuspendAction,
): PartnersState => {
  return {
    ...state,
    partnerToSuspend: action.partner,
  }
}

export function partnersReducer(state = initialState, action: PartnerActionTypes): PartnersState {
  switch (action.type) {
    case FETCH_PARTNERS_REQUEST:
      return fetchPartnersRequest(state, action)
    case FETCH_PARTNERS_SUCCESS:
      return fetchPartnersSuccess(state, action)
    case FETCH_PARTNERS_FAILURE:
      return fetchPartnersFailure(state, action)

    case FETCH_PARTNER_REQUEST:
      return fetchPartnerRequest(state, action)
    case FETCH_PARTNER_SUCCESS:
      return fetchPartnerSuccess(state, action)
    case FETCH_PARTNER_FAILURE:
      return fetchPartnerFailure(state, action)

    case FETCH_ROLE_REQUEST:
      return fetchRoleRequest(state, action)
    case FETCH_ROLE_SUCCESS:
      return fetchRoleSuccess(state, action)
    case FETCH_ROLE_FAILURE:
      return fetchRoleFailure(state, action)

    case ADD_USER_TO_PARTNER_REQUEST:
      return addUserToPartnerRequest(state, action)
    case ADD_USER_TO_PARTNER_SUCCESS:
      return addUserToPartnerSuccess(state, action)
    case ADD_USER_TO_PARTNER_FAILURE:
      return addUserToPartnerFailure(state, action)
    case ADD_USER_TO_PARTNER_MODAL:
      return addUserToPartnerModal(state, action)

    case SELECT_PARTNER:
      return selectPartner(state, action)

    case RESET_PARTNER:
      return resetPartner(state, action)

    case UPDATE_ROLE_ENTITLEMENTS_REQUEST:
      return updateRoleEntitlementsRequest(state, action)
    case UPDATE_ROLE_ENTITLEMENTS_SUCCESS:
      return updateRoleEntitlementsSuccess(state, action)
    case UPDATE_ROLE_ENTITLEMENTS_FAILURE:
      return updateRoleEntitlementsFailure(state, action)

    case ADD_PARTNER_MODAL_OPEN:
      return addPartnerModalOpen(state, action)
    case UPDATE_PARTNER_MODAL_OPEN:
      return updatePartnerModalOpen(state, action)

    case SUSPEND_PARTNER_REQUEST:
      return suspendPartnerRequest(state, action)
    case SUSPEND_PARTNER_SUCCESS:
      return suspendPartnerSuccess(state, action)
    case SUSPEND_PARTNER_FAILURE:
      return suspendPartnerFailure(state, action)

    case UNSUSPEND_PARTNER_REQUEST:
      return unsuspendPartnerRequest(state, action)
    case UNSUSPEND_PARTNER_SUCCESS:
      return unsuspendPartnerSuccess(state, action)
    case UNSUSPEND_PARTNER_FAILURE:
      return unsuspendPartnerFailure(state, action)

    case SUSPEND_ROLE_REQUEST:
      return suspendRoleRequest(state, action)
    case SUSPEND_ROLE_SUCCESS:
      return suspendRoleSuccess(state, action)
    case SUSPEND_ROLE_FAILURE:
      return suspendRoleFailure(state, action)

    case SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST:
      return suspendUserFromAllPartnersRequest(state, action)
    case SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS:
      return suspendUserFromAllPartnersSuccess(state, action)
    case SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE:
      return suspendUserFromAllPartnersFailure(state, action)

    case UNSUSPEND_ROLE_REQUEST:
      return unsuspendRoleRequest(state, action)
    case UNSUSPEND_ROLE_SUCCESS:
      return unsuspendRoleSuccess(state, action)
    case UNSUSPEND_ROLE_FAILURE:
      return unsuspendRoleFailure(state, action)

    case UPDATE_PARTNER_REQUEST:
      return updatePartnerRequest(state, action)
    case UPDATE_PARTNER_SUCCESS:
      return updatePartnerSuccess(state, action)
    case UPDATE_PARTNER_FAILURE:
      return updatePartnerFailure(state, action)

    case REMOVE_ROLE_FROM_PARTNER_REQUEST:
      return removeRoleFromPartnerRequest(state, action)
    case REMOVE_ROLE_FROM_PARTNER_SUCCESS:
      return removeRoleFromPartnerSuccess(state, action)
    case REMOVE_ROLE_FROM_PARTNER_FAILURE:
      return removeRoleFromPartnerFailure(state, action)

    case REMOVE_USER_FROM_ALL_PARTNERS_REQUEST:
      return removeUserFromAllPartnersRequest(state, action)
    case REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS:
      return removeUserFromAllPartnersSuccess(state, action)
    case REMOVE_USER_FROM_ALL_PARTNERS_FAILURE:
      return removeUserFromAllPartnersFailure(state)

    case SET_PARTNER_TO_SUSPEND:
      return setPartnerToSuspend(state, action)
    default:
      return state
  }
}
