import {
  ADD_TOASTER,
  REMOVE_TOASTER,
  UIActionTypes,
  UIState,
  AddToasterAction,
  RemoveToasterAction,
} from './types'

export const initialState: UIState = {
  toasterMessage: undefined,
}

export function addToaster(state: UIState, action: AddToasterAction) {
  return {
    ...state,
    toasterMessage: action.payload,
  }
}

export function removeToaster(state: UIState, action: RemoveToasterAction) {
  return {
    ...state,
    toasterMessage: undefined,
  }
}

export function uiReducer(state = initialState, action: UIActionTypes): UIState {
  switch (action.type) {
    case ADD_TOASTER:
      return addToaster(state, action)
    case REMOVE_TOASTER:
      return removeToaster(state, action)
    default:
      return state
  }
}
