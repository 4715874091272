import { Application, APPLICATIONS } from './Application'

export type Permissions = Partial<Record<Application, string[]>>

export type Entitlements = { application: Application; permissions: string[] }

export type ApplicationPermission = {
  id: string
  grouping: string
  category: string
  conditional?: string | string[] // TODO: Contract this expansion
}

export type ApplicationPermissionWithApp = ApplicationPermission & {
  application: Application
}

export type ApplicationPermissions = Record<Application, ApplicationPermission[]>
export type ApplicationPermissionsWithApp = Record<Application, ApplicationPermissionWithApp[]>

// Update when adding new applications
export const EMPTY_APPLICATION_PERMISSION_SET = Object.fromEntries(
  APPLICATIONS.map((a) => [a.id, []]),
) as unknown as ApplicationPermissions

export const EMPTY_PERMISSION_SET: NonNullable<Permissions> = Object.fromEntries(
  APPLICATIONS.map((a) => [a.id, []]),
)

export const entitlementsToPermissions = (entitlements: Entitlements[]): Permissions =>
  entitlements?.reduce((acc, curr) => {
    return { ...acc, [curr.application]: curr.permissions }
  }, {} as Permissions)

export const permissionsToEntitlements = (permissions: Permissions): Entitlements[] =>
  Object.keys(permissions).map(
    (app) =>
      ({
        application: app,
        permissions: permissions[app],
      } as Entitlements),
  )
