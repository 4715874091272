import { UserDialog } from '../../../shared/model/User'
import {
  UsersState,
  RemoveUserRequestAction,
  RemoveUserSuccessAction,
  RemoveUserFailureAction,
} from '../types'

export const removeUserRequest = (
  state: UsersState,
  action: RemoveUserRequestAction,
): UsersState => {
  const userDialog: UserDialog = {
    ...state.userDialog,
    executingAction: true,
  }
  return {
    ...state,
    userDialog,
  }
}
export const removeUserSuccess = (
  state: UsersState,
  action: RemoveUserSuccessAction,
): UsersState => {
  return {
    ...state,
    ...removeUserLocally(state, action),
  }
}
export const removeUserFailure = (
  state: UsersState,
  action: RemoveUserFailureAction,
): UsersState => {
  const userDialog: UserDialog = {
    ...state.userDialog,
    executingAction: false,
  }
  return {
    ...state,
    userDialog,
  }
}

const removeUserLocally = (
  state: UsersState,
  action: RemoveUserSuccessAction,
): Partial<UsersState> => {
  const stateUser = state.user
  const userId = action.user.id
  const stateUsers = state.users
  return {
    ...state,
    userDialog: undefined,
    user: stateUser !== undefined ? (stateUser.id === userId ? undefined : stateUser) : undefined,
    users: stateUsers.filter((user) => user.id !== userId),
  }
}
