import { useAccount } from '@azure/msal-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const useProfile = (): {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  preferredLanguage: string | undefined
  assumedBy: { id: string | undefined; name: string | undefined }
} => {
  const account = useAccount()
  const claims = account?.idTokenClaims as Record<string, string> | undefined
  const firstName = claims?.['given_name']
  const lastName = claims?.['family_name']
  const preferredLanguage = claims?.['preferredLanguage']
  const assumedByUserId = claims?.['assumedByUserId']
  const assumedByUserDisplayName = claims?.['assumedByUserDisplayName']
  const email = claims?.['email']

  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(claims?.preferredLanguage)
  }, [claims?.preferredLanguage, i18n])

  return {
    firstName,
    lastName,
    email,
    preferredLanguage,
    assumedBy: {
      id: assumedByUserId,
      name: assumedByUserDisplayName,
    },
  }
}
