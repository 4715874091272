import { markets, SalesDivision } from './Application'
import { Entitlements } from './Permission'

export interface Partner {
  id: string
  name: string
  roles: Role[]
  admins: string[]
  attributes: Attribute[]
  suspended: boolean
}

export interface Attribute {
  key: string
  value: any
}

export interface Role {
  partnerId: string
  userId: string
  entitlements: Entitlements[]
  attributes: { externalSapId?: string }
  createdAt?: Date
  isFetching?: boolean
}

export const getPartnerAttribute = (partner: Partner, attributeKey: string) => {
  return partner.attributes.find((attribute) => attribute.key === attributeKey)?.value
}

export const getPartnerMarket = (partner: Partner): string => {
  return (
    markets.find((market) => market.value === getPartnerAttribute(partner, 'market'))?.title ?? ''
  )
}

export const getSalesDivisionFromPartner = (
  partner: Partner,
  salesDivisions: SalesDivision[],
): SalesDivision => {
  const businessArea: string = getPartnerAttribute(partner, 'businessArea')
  return (
    salesDivisions.find((salesDivision) => salesDivision.businessArea === businessArea) ?? {
      id: '',
      name: businessArea,
      market: '',
      businessArea: businessArea,
    }
  )
}

export const isPartnerActive = (partner?: Partner): boolean => {
  return !!partner && !partner.suspended
}

export enum PartnerStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
}

export function createPartnerFromAxiosResponse(partner: any): Partner {
  return {
    id: partner.id,
    name: partner.name,
    roles: [],
    admins: [],
    attributes: createPartnerAttributes(partner),
    suspended: partner.suspended ?? false,
  } satisfies Partner
}

export function createPartnerAttributes(partner: any): Attribute[] {
  const salesStatistics = partner.showInSalesStatistics ? 'EXTERNAL' : 'INTERNAL'
  return [
    { key: 'partnerId', value: partner.id },
    { key: 'market', value: partner.salesArea.market },
    { key: 'businessArea', value: partner.salesArea.region },
    { key: 'partnerLevel', value: partner.partnerLevel },
    { key: 'customerId', value: partner.customerId },
    { key: 'salesStatisticsAvailability', value: salesStatistics },
    {
      key: 'customerContact',
      value: partner.contacts?.endCustomerSupport?.pop() ?? {
        email: '',
        phone: '',
        address: {
          country: '',
          city: '',
          street: '',
          zipCode: '',
        },
      },
    },
  ]
}
