import React from 'react'
import { RouteProps } from 'react-router'
import { Route } from 'react-router-dom'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../../msalConfig'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route {...rest}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          ...loginRequest,
        }}
      >
        {children}
      </MsalAuthenticationTemplate>
    </Route>
  )
}
export default PrivateRoute
