import {
  PartnersState,
  UpdateRoleEntitlementsRequestAction,
  UpdateRoleEntitlementsSuccessAction,
  UpdateRoleEntitlementsFailureAction,
} from '../types'

export const updateRoleEntitlementsRequest = (
  state: PartnersState,
  action: UpdateRoleEntitlementsRequestAction,
): PartnersState => {
  return {
    ...state,
    updateUserPermissionLoading: true,
  }
}

export const updateRoleEntitlementsSuccess = (
  state: PartnersState,
  action: UpdateRoleEntitlementsSuccessAction,
): PartnersState => {
  return {
    ...state,
    updateUserPermissionLoading: false,
    ...updatePermissionsInPartnerLocally(state, action),
  }
}

export const updateRoleEntitlementsFailure = (
  state: PartnersState,
  action: UpdateRoleEntitlementsFailureAction,
): PartnersState => {
  return {
    ...state,
    updateUserPermissionLoading: false,
  }
}

/**
 * Takes a state and UpdateRoleEntitlementsSuccessAction
 * and updates the affected partner/partners locally. Sort of
 * an optimistic update, but not quite since we do this on
 * a successful API call.
 */
const updatePermissionsInPartnerLocally = (
  state: PartnersState,
  action: UpdateRoleEntitlementsSuccessAction,
) => {
  const currentPartner =
    state.partner?.id === action.partnerId
      ? state.partner
      : state.partners.find((p) => p.id === action.partnerId)!
  const currentRole = currentPartner.roles.find((r) => r.userId === action.userId)
  if (!currentRole) {
    return {}
  }
  const updatedEntitlementsApplications = action.entitlements.map((ent) => ent.application)
  const currentEntitlements = currentRole.entitlements.filter(
    (ent) => !updatedEntitlementsApplications.includes(ent.application),
  )
  const newPartner = {
    ...currentPartner,
    roles: [
      ...currentPartner.roles.filter((r) => r.userId !== action.userId),
      {
        ...currentRole,

        entitlements: action.entitlements.concat(currentEntitlements),
      },
    ],
  }

  return {
    partner: newPartner,
    partners: [...state.partners.filter((p) => p.id !== newPartner.id), newPartner],
  }
}
