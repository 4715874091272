import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@mui/icons-material/Home'
import { ReactComponent as CompaniesIcon } from '../../../assets/icons/companies.svg'
import { ReactComponent as UsersIcon } from '../../../assets/icons/users.svg'
import classes from './NavigationItem.module.css'
import { Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import theme from '../../../hoc/layout/theme'

export interface NavigationItemProps {
  label: string
  sublabel?: string
  iconName?: string
  rightAlignedText?: string
  loading?: boolean
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  iconName,
  sublabel,
  rightAlignedText,
  loading,
}) => {
  // TODO: Solve this nicer by passing the component instead of this if mess
  let icon = null
  if (iconName === 'home') {
    icon = <HomeIcon className={classes.NavigationIcon} />
  } else if (iconName === 'groups') {
    icon = <UsersIcon />
  } else if (iconName === 'domain') {
    icon = <CompaniesIcon />
  }

  return (
    <ListItem
      className={classes.ListItem}
      sx={{
        padding: '0px 30px',
        margin: '10px 0px',
        minHeight: '55px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        className={classes.NavigationItem}
        primary={<Typography>{label}</Typography>}
        secondary={
          sublabel && (
            <Typography style={{ fontWeight: 900 }} variant='subtitle2'>
              {sublabel}
            </Typography>
          )
        }
      ></ListItemText>
      {rightAlignedText !== undefined && (
        <ListItemText
          className={classes.NavigationItem}
          primary={
            <Typography align='right'>
              {loading ? (
                <CircularProgress size={20} sx={{ color: theme.palette.medium.main }} />
              ) : (
                rightAlignedText
              )}
            </Typography>
          }
        />
      )}
    </ListItem>
  )
}

export default NavigationItem
