import { Partner } from '../../../shared/model/Partner'
import { User } from '../../../shared/model/User'
import {
  PartnersState,
  RemoveUserFromAllPartnersRequestAction,
  RemoveUserFromAllPartnersSuccessAction,
} from '../types'

export const removeUserFromAllPartnersRequest = (
  state: PartnersState,
  action: RemoveUserFromAllPartnersRequestAction,
): PartnersState => {
  return {
    ...state,
  }
}

export const removeUserFromAllPartnersSuccess = (
  state: PartnersState,
  action: RemoveUserFromAllPartnersSuccessAction,
): PartnersState => {
  return {
    ...state,
    ...removeUserFromAllPartnersLocally(state, action),
  }
}
export const removeUserFromAllPartnersFailure = (state: PartnersState): PartnersState => {
  return {
    ...state,
  }
}

const removeUserFromAllPartnersLocally = (
  state: PartnersState,
  action: RemoveUserFromAllPartnersSuccessAction,
): Partial<PartnersState> => {
  const partners = action.partnerIds
  const statePartners = state.partners
  const user = action.user

  const removeRole = (partner: Partner, user: User) => {
    const newRoles = partner.roles.filter((role) => role.userId !== user.id)
    return { ...partner, roles: newRoles }
  }
  const newPartners = statePartners.map((partner) => {
    return partners.includes(partner.id) ? removeRole(partner, user) : partner
  })

  return {
    ...state,
    partners: newPartners,
  }
}
