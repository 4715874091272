import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationDk from './assets/lang/da/translation.json'
import translationEn from './assets/lang/en/translation.json'
import translationFi from './assets/lang/fi/translation.json'
import translationNo from './assets/lang/no/translation.json'
import translationSv from './assets/lang/sv/translation.json'

const languageResources = {
  da: {
    translations: translationDk,
  },
  en: {
    translations: translationEn,
  },
  fi: {
    translations: translationFi,
  },
  no: {
    translations: translationNo,
  },
  sv: {
    translations: translationSv,
  },
}

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: languageResources,
  lng: navigator.language || 'en',
  fallbackLng: {
    nb: ['no', 'en'],
    default: ['en'],
  },
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: '@',
  returnEmptyString: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
})

export function getSupportedLanguages() {
  return Object.keys(i18n.services.resourceStore.data)
}

export function getCurrentLanguageCode() {
  const currentLanguage: string = i18n.language
  const languageCode: string = currentLanguage.split('-')[0].toUpperCase()
  return languageCode
}

export default i18n
