import { AxiosResponse } from 'axios'
import axiosInstance from '../../../axios'
import { User } from '../../../shared/model/User'
import { AppDispatch, RootState } from '../../store'
import { Partner } from '../../../shared/model/Partner'
import { fetchPartners } from '../../partners/fetchPartners/actions'

async function fetchAllPartners(
  dispatch: AppDispatch,
  getState: () => RootState,
): Promise<[Partner[], User[]]> {
  const existingPartners = getState().partners.partners
  const promises: [Promise<Partner[]>, Promise<AxiosResponse<User[]>>] = [
    existingPartners.length > 0 ? Promise.resolve(existingPartners) : dispatch(fetchPartners()),
    axiosInstance.get<User[]>('/user/users'),
  ]
  return Promise.all(promises).then(([partners, { data: users }]) => {
    return [partners, users]
  })
}

async function fetchUsers(partner: Partner): Promise<User[]> {
  const userIds = partner.roles.map((roles) => roles.userId)
  const promises = []
  while (userIds.length > 0) {
    // avoid too long URLs, 37 characters per ID incl comma
    const chunk = userIds.splice(0, 100)
    promises.push(axiosInstance.get<User[]>(`/user/users?userIds=${chunk.join(',')}`))
  }
  return Promise.all(promises).then((results) =>
    results
      .map(({ data: users }) => {
        return users
      })
      .flat(),
  )
}

export const createUsers = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  forPartner?: Partner,
): Promise<User[]> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [partners, users] = !!forPartner
      ? [[forPartner], await fetchUsers(forPartner)]
      : await fetchAllPartners(dispatch, getState)
    return users
  } catch (error) {
    return Promise.reject(error)
  }
}
