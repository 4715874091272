import { CircularProgress } from '@mui/material'
import React from 'react'

const SuspenseFallback = () => {
  return (
    <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
      <CircularProgress />
    </div>
  )
}

export default SuspenseFallback
