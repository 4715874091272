import { User, UserDialogAction } from '../../shared/model/User'

import {
  UsersActionTypes,
  ADD_USER_MODAL_OPEN,
  CLOSE_USER_DIALOG,
  CHANGE_USER_MODAL_OPEN,
  VIEW_USER_MODAL_OPEN,
  EDITING_USER_PERMISSION,
  SET_USER_DIALOG,
} from './types'

import { Partner } from '../../shared/model/Partner'

export * from './fetchUsers/actions'
export * from './inviteUser/actions'
export * from '../partners/suspendUserFromAllPartners/actions'

export function setUserDialog(
  dialogAction: UserDialogAction,
  user: User,
  partner?: Partner,
  partners?: Partner[],
): UsersActionTypes {
  return {
    type: SET_USER_DIALOG,
    action: dialogAction,
    user,
    partner,
    partners,
  }
}

export function closeUserDialog(): UsersActionTypes {
  return {
    type: CLOSE_USER_DIALOG,
  }
}

export function addUserModalOpen(open: boolean): UsersActionTypes {
  return {
    type: ADD_USER_MODAL_OPEN,
    open,
  }
}

export function changeUserModalOpen(open: boolean): UsersActionTypes {
  return {
    type: CHANGE_USER_MODAL_OPEN,
    open,
  }
}

export function viewUserModalOpen(open: boolean): UsersActionTypes {
  return {
    type: VIEW_USER_MODAL_OPEN,
    open,
  }
}

export function editingUserPermissions(status: boolean): UsersActionTypes {
  return {
    type: EDITING_USER_PERMISSION,
    status,
  }
}
