import { Attribute, Partner, Role, createPartnerAttributes } from '../../shared/model/Partner'
import axiosInstance from '../../axios'

export class PartnerService {
  static async getPartner(partnerId: string): Promise<Partner> {
    const getPartnerURL = `/partner-repository/partners/${partnerId}`
    const partnerResponse = await axiosInstance.get(getPartnerURL)

    const getRoleUrl = `/role/roles?partnerIds=${partnerId}`
    const roleResponse = await axiosInstance.get<Role[]>(getRoleUrl)

    const partner = this.mapResponseToPartner(partnerResponse.data, roleResponse.data)
    return partner
  }

  static async getPartners(): Promise<Partner[]> {
    const getPartnerURL = `/partner-repository/partners`
    const partnerResponse = await axiosInstance.get(getPartnerURL)

    const getRoleUrl = `/role/roles`
    const roleResponse = await axiosInstance.get<Role[]>(getRoleUrl)

    const partners = partnerResponse.data.map((partner: any) =>
      this.mapResponseToPartner(
        partner,
        roleResponse.data.filter((role) => role.partnerId === partner.id),
      ),
    )
    return partners
  }

  static mapResponseToPartner(partnerResponse: any, roleResponse: Role[]): Partner {
    const admins: string[] = roleResponse
      .filter((role) => {
        return role.entitlements.some((ent) =>
          ent.application === 'ciam' ? ent.permissions.includes('ciam.assign') : false,
        )
      })
      .map((role) => {
        return role.userId
      })

    const partnerObj: Partner = {
      id: partnerResponse.id as string,
      name: partnerResponse.companyName as string,
      roles: roleResponse.filter((r) => r.partnerId === partnerResponse.id) as Role[],
      admins: admins as string[],
      attributes: createPartnerAttributes(partnerResponse) as Attribute[],
      suspended: partnerResponse.suspended ?? false,
    }
    return partnerObj
  }
}
