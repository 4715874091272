import React, { FC, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from '@mui/material/styles'
import { Theme } from '@emotion/react'
import { MsalProvider } from '@azure/msal-react'
import theme from './hoc/layout/theme'
import i18n from './i18n'
import msalInstance from './msalConfig'
import Routes from './Routes'
import Layout from './hoc/layout/Layout'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const queryLanguage = query.get('lang')
    if (!queryLanguage) return
    i18n.changeLanguage(queryLanguage)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <Layout>
              <Routes />
            </Layout>
          </BrowserRouter>
        </MsalProvider>
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default App
