import React from 'react'
import { makeStyles } from '@mui/styles'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import Toaster from '../../components/UI/Toaster/Toaster'
import theme from './theme'

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
    },
    main: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      height: 'calc(100vh - 64px)',
    },
  }),
  {
    name: 'thermia',
  },
)

const Layout: React.FC = (props) => {
  const material = useStyles()
  const [sideBarOpen, openSidebar] = React.useState(false)
  const handleDrawerToggle = () => {
    openSidebar(!sideBarOpen)
  }

  return (
    <div className={material.root}>
      <Sidebar open={sideBarOpen} onClose={handleDrawerToggle} />
      <div className={material.content}>
        <Header toggleSidebar={handleDrawerToggle} />
        <Toaster />
        <main className={material.main}>{props.children}</main>
      </div>
    </div>
  )
}

export default Layout
