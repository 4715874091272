import {
  PartnersState,
  UnsuspendPartnerRequestAction,
  UnsuspendPartnerSuccessAction,
  UnsuspendPartnerFailureAction,
} from '../types'

export const unsuspendPartnerRequest = (
  state: PartnersState,
  action: UnsuspendPartnerRequestAction,
): PartnersState => {
  return {
    ...state,
    partnerStatusLoading: true,
  }
}

export const unsuspendPartnerSuccess = (
  state: PartnersState,
  action: UnsuspendPartnerSuccessAction,
): PartnersState => {
  const statePartners = state.partners
  const partnerId = action.partner.id
  const updatedPartner = { ...action.partner, suspended: false }
  return {
    ...state,
    partnerStatusLoading: false,
    partners: [
      ...statePartners.filter((partner) => partner.id !== partnerId).concat(updatedPartner),
    ],
  }
}

export const unsuspendPartnerFailure = (
  state: PartnersState,
  action: UnsuspendPartnerFailureAction,
): PartnersState => {
  return {
    ...state,
    partnerStatusLoading: false,
  }
}
