import {
  PartnersState,
  PartnerActionTypes,
  FetchPartnerFailureAction,
  FetchPartnerSuccessAction,
} from '../types'

export const fetchPartnerRequest = (
  state: PartnersState,
  action: PartnerActionTypes,
): PartnersState => {
  return {
    ...state,
    loadingPartner: true,
  }
}

export const fetchPartnerSuccess = (
  state: PartnersState,
  action: FetchPartnerSuccessAction,
): PartnersState => {
  return {
    ...state,
    partner: action.payload,
    partners: [...state.partners.filter((p) => p.id !== action.payload.id), action.payload],
    loadingPartner: false,
  }
}

export const fetchPartnerFailure = (
  state: PartnersState,
  action: FetchPartnerFailureAction,
): PartnersState => {
  return {
    ...state,
    partner: undefined,
    error: action.payload,
    loadingPartner: false,
  }
}
