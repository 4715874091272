import {
  ResendUserInviteFailureAction,
  ResendUserInviteRequestAction,
  ResendUserInviteSuccessAction,
  UsersState,
} from '../types'

export const resendUserInviteRequest = (
  state: UsersState,
  action: ResendUserInviteRequestAction,
): UsersState => {
  return {
    ...state,
    resendUserInviteLoading: {
      userId: action.userId,
      loading: true,
    },
  }
}

export const resendUserInviteSuccess = (
  state: UsersState,
  action: ResendUserInviteSuccessAction,
): UsersState => {
  return {
    ...state,
    resendUserInviteLoading: {
      ...state.resendUserInviteLoading,
      loading: false,
    },
  }
}

export const resendUserInviteFailure = (
  state: UsersState,
  action: ResendUserInviteFailureAction,
): UsersState => {
  return {
    ...state,
    resendUserInviteLoading: {
      ...state.resendUserInviteLoading,
      loading: false,
    },
  }
}
