import { RedirectRequest } from '@azure/msal-browser'
import { useMsal, useAccount } from '@azure/msal-react'
import { loginRequest } from '../msalConfig'
import { getConfig } from '../utils'

const APP_CONFIG = getConfig()

export const useAuth = () => {
  const { instance: msalInstance } = useMsal()
  const account = useAccount()

  return {
    editProfile: () => {
      msalInstance.loginRedirect({
        ...loginRequest,
        authority: `${APP_CONFIG.b2c.authority}/${APP_CONFIG.b2c.policies.profileEdit}`,
        extraQueryParameters: {
          ui_locales: (account?.idTokenClaims?.preferredLanguage as string) ?? 'en',
        },
      })
    },
    changePassword: () => {
      msalInstance.loginRedirect({
        ...loginRequest,
        authority: `${APP_CONFIG.b2c.authority}/${APP_CONFIG.b2c.policies.forgotPassword}`,
        extraQueryParameters: {
          ui_locales: (account?.idTokenClaims?.preferredLanguage as string) ?? 'en',
        },
      })
    },
    logout: () => {
      msalInstance.logoutRedirect()
    },
    logoutAllAccountsLocally: () => {
      msalInstance.getAllAccounts().forEach((acc) => {
        msalInstance.logout({ account: acc, onRedirectNavigate: () => false })
      })
    },
    getInviteSignUpRequest: (inviteToken: string, language: string = 'sv') => {
      const signupRequest: RedirectRequest = {
        ...loginRequest,
        authority: `${APP_CONFIG.b2c.authority}/${APP_CONFIG.b2c.policies.signUp}`,
        extraQueryParameters: {
          id_token_hint: inviteToken,
          ui_locales: language, // the email link should have a lang paramter so this is correctly set
        },
        redirectUri: `/redirect/invite`,
        redirectStartPage: '/redirect/invite',
      }
      return signupRequest
    },
  }
}
