import { useIsAuthenticated } from '@azure/msal-react'
import MenuIcon from '@mui/icons-material/Menu'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { Box, Typography } from '@mui/material'
import Appbar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProfile } from '../../hooks/useProfile'
import classes from './Header.module.css'
import LanguageMenu from './LanguageMenu'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg'
import { ReactComponent as DownArrowIcon } from '../../assets/icons/arrow-down.svg'
import ProfileMenu from './ProfileMenu'
import { getCurrentLanguageCode } from '../../i18n'

interface HeaderProps {
  toggleSidebar: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const { i18n } = useTranslation()
  const profile = useProfile()

  const isAuthenticated = useIsAuthenticated()
  const name = (
    <span>
      {profile?.firstName} {profile?.lastName}
    </span>
  )

  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)

  const handleProfileClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElProfile(event.currentTarget)
  }

  const handleLanguageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseProfile = () => {
    setAnchorElProfile(null)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  return (
    <Appbar position='static' elevation={0}>
      <div className={classes.Header}>
        <Hidden mdUp implementation='css'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            className={classes.menuButton}
            onClick={toggleSidebar}
            size='large'
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        {isAuthenticated && (
          <>
            <div
              className={classes.ProfileLink}
              aria-controls='profile-menu'
              aria-haspopup='true'
              onClick={handleProfileClick}
            >
              {profile.assumedBy.name ? (
                <SupervisedUserCircleIcon style={{ fontSize: 20 }} />
              ) : (
                <ProfileIcon />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='body1' align='center' style={{ lineHeight: '1' }}>
                  {name}
                </Typography>
                {profile.assumedBy.name && (
                  <Typography
                    variant='caption'
                    align='center'
                    style={{
                      color: 'rgba(247, 247, 247, 0.6)',
                      lineHeight: '1',
                    }}
                  >
                    {`${i18n.t('as')} ${profile.assumedBy.name.split(' ')[0]}`}
                  </Typography>
                )}
              </Box>
            </div>
            <ProfileMenu
              id='profile-menu'
              anchorEl={anchorElProfile}
              onClose={handleCloseProfile}
            />
            <div
              className={classes.LanguageButton}
              aria-controls='language-menu'
              aria-haspopup='true'
              onClick={handleLanguageClick}
            >
              <Typography variant='body1' align='center' style={{ lineHeight: '1', fontSize: 14 }}>
                {getCurrentLanguageCode()}
              </Typography>
              <DownArrowIcon />
            </div>
            <LanguageMenu
              id='language-menu'
              anchorEl={anchorElLanguage}
              onClose={handleCloseLanguage}
            />
          </>
        )}
      </div>
    </Appbar>
  )
}

export default Header
