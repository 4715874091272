import { createTheme, Shadows } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    primaryDark: Palette['primary']
    medium: Palette['primary']
    light: Palette['primary']
    strong: Palette['primary']
    tertiary: Palette['primary']
  }
  interface PaletteOptions {
    primaryDark: PaletteOptions['primary']
    medium: PaletteOptions['primary']
    light: PaletteOptions['primary']
    strong: PaletteOptions['primary']
    tertiary: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryDark: true
    strong: true
    tertiary: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    menuButton: true
    menuButtonContained: true
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsVariantOverrides {
    menuButton: true
    menuButtonContained: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    applicationBadge: true
    statusIndicator: true
  }
  interface ChipPropsColorOverrides {
    medium: true
    light: true
    strong: true
    primaryDark: true
  }
}

const colors = {
  background: {
    light: '#D7E4F4',
    extraLight: '#EDF3FA',
    medium: '#8AB0DE',
    strong: '#004472',
  },
  selection: {
    primary: {
      background: '#005CAA',
      hover: '#00315C',
      hoverMaybe: '#0069C2',
    },
    secondary: '#002240',
    tertiary: { background: '#88221F' },
  },
  text: {
    general: '#000000',
    light: '#9D9D9D',
    medium: '#575757',
    black: '#000000',
    white: '#ffffff',
    red: '#C41E27',
    disabled: '#a3a3a3',
  },
  status: {
    success: '#22BC9F',
    disabled: '#DFDFDF',
  },
  general: {
    border: '#E7E8EF',
  },
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.background.extraLight,
      dark: colors.background.light,
    },
    primaryDark: {
      main: colors.selection.primary.background,
      dark: colors.selection.secondary,
      contrastText: colors.text.white,
    },
    secondary: {
      main: colors.selection.primary.background,
    },
    tertiary: {
      main: colors.selection.tertiary.background,
      contrastText: colors.text.white,
    },
    medium: {
      main: colors.background.medium,
      contrastText: colors.text.white,
    },
    light: {
      main: colors.background.light,
      contrastText: colors.text.black,
    },
    strong: {
      main: colors.background.strong,
      contrastText: colors.text.white,
    },
    success: {
      main: colors.status.success,
    },
    grey: {
      '300': colors.general.border,
      '400': colors.status.disabled,
      '500': colors.text.disabled,
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75rem',
      color: colors.text.medium,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '0.875rem',
      color: colors.text.medium,
      fontWeight: 700,
    },
  } as any,
  shadows: Array(25).fill('none') as Shadows,
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          '&:not(.Mui-focused)': {
            '& fieldset': { border: 'none' },
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            '&:not(.Mui-focused)': {
              border: `2px solid ${colors.general.border}`,
            },
            '&.Mui-focused': {
              border: `2px solid ${colors.selection.primary.background}`,
            },
          },
          '& label': {
            color: colors.text.medium,
            transformOrigin: '-50px -50px',
          },
          '& label.Mui-focused': {
            color: colors.text.medium,
          },
          '&.subvariant-searchbar': {
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
              '&:not(.Mui-focused)': {
                border: 'none',
              },
            },
            maxWidth: '489px',
            height: '50px',
            '& label': {
              color: colors.text.medium,
              transformOrigin: '-50px -50px',
            },
          },
          '&.groupFilter': {
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
              '&:not(.Mui-focused)': {
                border: 'none',
              },
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.675rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.text.medium,
          '&.Mui-focused': { color: colors.selection.primary.background },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.user-or-company-list': {
            height: '62px',
            borderBottom: '10px solid white',
            backgroundColor: colors.background.extraLight,
            '&.MuiTableRow-root:hover': {
              backgroundColor: colors.background.light,
            },
          },
          '&.header': {
            height: '62px',
            borderBottom: '10px solid white',
            backgroundColor: colors.background.light,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '0px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          marginTop: '4px',
          backgroundColor: 'white',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          '& fieldset:not(.Mui-focused)': { border: 'none' },
          '& placeholder': {
            color: colors.text.medium,
            transformOrigin: '-50px -50px',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: '30%',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: 'none',
          height: '50px',
          '& fieldset': { border: 'none' },
        },
      } as any,
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.675rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          textTransform: 'capitalize',
        },
        contained: {
          display: 'flex',
          padding: '11px 32px',
          height: '40px',
          gap: '10px',
          borderRadius: '50px',
        },
        text: {
          color: colors.text.medium,
          borderRadius: '50px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: colors.selection.primary.background,
            color: colors.text.white,
            '&:hover': {
              backgroundColor: colors.selection.primary.hoverMaybe,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: colors.selection.secondary,
            color: colors.text.white,
            '&:hover': {
              backgroundColor: colors.selection.primary.hover,
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: colors.selection.primary.background,
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            color: colors.text.red,
          },
        },
        {
          props: { variant: 'text', color: 'tertiary' },
          style: {
            color: colors.text.black,
          },
        },
        {
          props: { variant: 'menuButton' },
          style: {
            display: 'flex',
            padding: '0px',
            paddingLeft: '30px',
            width: '100%',
            height: '43px',
            gap: '10px',
            borderRadius: '0px',
            justifyContent: 'left',
            color: colors.text.black,
            backgroundColor: 'transparent',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: colors.background.light,
            },
          },
        },
        {
          props: { variant: 'menuButton', color: 'secondary' },
          style: {
            background: colors.selection.primary.background,
            color: colors.text.white,
            '&:hover': {
              backgroundColor: colors.selection.primary.hover,
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'applicationBadge' },
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            fontSize: '14px',
            height: '26px',
            borderRadius: '12px',
            margin: '3px 4px',
            boxShadow: '0px 1px 3px 0px rgba(138, 176, 222, 0.15)',
          },
        },
        {
          props: { variant: 'statusIndicator' },
          style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            borderRadius: 0,
            '& .MuiChip-icon': {
              order: 1,
              margin: '0',
              marginLeft: '-6px',
              height: '20px',
            },
            '& .MuiChip-label': { fontSize: '0.875rem', paddingLeft: '0px' },
          },
        },
      ],
    },
  },
})

export default theme
