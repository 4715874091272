import { Partner, Role } from '../../shared/model/Partner'
import { Entitlements } from '../../shared/model/Permission'
import { User } from '../../shared/model/User'
import { Error } from '../../shared/model/Utils'

export const UPDATE_ROLE_ENTITLEMENTS_REQUEST = 'UPDATE_ROLE_ENTITLEMENTS_REQUEST'
export const UPDATE_ROLE_ENTITLEMENTS_SUCCESS = 'UPDATE_ROLE_ENTITLEMENTS_SUCCESS'
export const UPDATE_ROLE_ENTITLEMENTS_FAILURE = 'UPDATE_ROLE_ENTITLEMENTS_FAILURE'

export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST'
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS'
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE'

export const FETCH_PARTNER_REQUEST = 'FETCH_PARTNER_REQUEST'
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS'
export const FETCH_PARTNER_FAILURE = 'FETCH_PARTNER_FAILURE'

export const FETCH_ROLE_REQUEST = 'FETCH_ROLE_REQUEST'
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS'
export const FETCH_ROLE_FAILURE = 'FETCH_ROLE_FAILURE'

export const ADD_USER_TO_PARTNER_MODAL = 'ADD_USER_TO_PARTNER_MODAL'
export const ADD_USER_TO_PARTNER_REQUEST = 'ADD_USER_TO_PARTNER_REQUEST'
export const ADD_USER_TO_PARTNER_SUCCESS = 'ADD_USER_TO_PARTNER_SUCCESS'
export const ADD_USER_TO_PARTNER_FAILURE = 'ADD_USER_TO_PARTNER_FAILURE'

export const SUSPEND_PARTNER_REQUEST = 'SUSPEND_PARTNER_REQUEST'
export const SUSPEND_PARTNER_SUCCESS = 'SUSPEND_PARTNER_SUCCESS'
export const SUSPEND_PARTNER_FAILURE = 'SUSPEND_PARTNER_FAILURE'

export const UNSUSPEND_PARTNER_REQUEST = 'UNSUSPEND_PARTNER_REQUEST'
export const UNSUSPEND_PARTNER_SUCCESS = 'UNSUSPEND_PARTNER_SUCCESS'
export const UNSUSPEND_PARTNER_FAILURE = 'UNSUSPEND_PARTNER_FAILURE'

export const SUSPEND_ROLE_REQUEST = 'SUSPEND_ROLE_REQUEST'
export const SUSPEND_ROLE_SUCCESS = 'SUSPEND_ROLE_SUCCESS'
export const SUSPEND_ROLE_FAILURE = 'SUSPEND_ROLE_FAILURE'

export const UNSUSPEND_ROLE_REQUEST = 'UNSUSPEND_ROLE_REQUEST'
export const UNSUSPEND_ROLE_SUCCESS = 'UNSUSPEND_ROLE_SUCCESS'
export const UNSUSPEND_ROLE_FAILURE = 'UNSUSPEND_ROLE_FAILURE'

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST'
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS'
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE'

export const SELECT_PARTNER = 'SELECT_PARTNER'
export const ADD_PARTNER_MODAL_OPEN = 'ADD_PARTNER_MODAL_OPEN'
export const UPDATE_PARTNER_MODAL_OPEN = 'UPDATE_PARTNER_MODAL_OPEN'
export const SET_PARTNER_TO_SUSPEND = 'SET_PARTNER_TO_SUSPEND'
export const RESET_PARTNER = 'RESET_PARTNER'

export const SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE = 'SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE'
export const SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST = 'SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST'
export const SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS = 'SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS'

export const REMOVE_ROLE_FROM_PARTNER_REQUEST = 'REMOVE_ROLE_FROM_PARTNER_REQUEST'
export const REMOVE_ROLE_FROM_PARTNER_SUCCESS = 'REMOVE_ROLE_FROM_PARTNER_SUCCESS'
export const REMOVE_ROLE_FROM_PARTNER_FAILURE = 'REMOVE_ROLE_FROM_PARTNER_FAILURE'

export const REMOVE_USER_FROM_ALL_PARTNERS_REQUEST = 'REMOVE_USER_FROM_ALL_PARTNERS_REQUEST'
export const REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS = 'REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS'
export const REMOVE_USER_FROM_ALL_PARTNERS_FAILURE = 'REMOVE_USER_FROM_ALL_PARTNERS_FAILURE'

export interface PartnersState {
  partner: Partner | undefined
  partners: Partner[]
  partnersFetched: boolean
  loadingPartners: boolean
  loadingPartner: boolean
  refreshingPartners: boolean
  addUserToPartnerModal: boolean
  addUserToPartnerLoading: boolean
  addUserToPartnerError: Error | undefined
  error: Error | undefined
  addPartnerModalOpen: boolean
  updatePartnerModalOpen: boolean
  updatePartnerLoading: boolean
  partnerToSuspend: Partner | undefined
  partnerStatusLoading: boolean
  updateUserPermissionLoading: boolean
  updateUserAdminStatusLoading: boolean
  role: {
    roleSuspendLoading: boolean
    roleUnsuspendLoading: boolean
  }
}

export interface FetchPartnersRequestAction {
  type: typeof FETCH_PARTNERS_REQUEST
  isRefresh: boolean
}

export interface FetchPartnersRequestAction {
  type: typeof FETCH_PARTNERS_REQUEST
  isRefresh: boolean
}

export interface FetchPartnersSuccessAction {
  type: typeof FETCH_PARTNERS_SUCCESS
  payload: Partner[]
}

export interface FetchRoleFailureAction {
  type: typeof FETCH_ROLE_FAILURE
  userId: string
  partnerId: string
}

export interface FetchRoleRequestAction {
  type: typeof FETCH_ROLE_REQUEST
  userId: string
  partnerId: string
}

export interface FetchRoleSuccessAction {
  type: typeof FETCH_ROLE_SUCCESS
  payload: Role
}

export interface FetchPartnersFailureAction {
  type: typeof FETCH_PARTNERS_FAILURE
  payload: Error
}

export interface FetchPartnerRequestAction {
  type: typeof FETCH_PARTNER_REQUEST
}

export interface FetchPartnerSuccessAction {
  type: typeof FETCH_PARTNER_SUCCESS
  payload: Partner
}

export interface FetchPartnerFailureAction {
  type: typeof FETCH_PARTNER_FAILURE
  payload: Error
}

export interface AddUserToPartnerRequestAction {
  type: typeof ADD_USER_TO_PARTNER_REQUEST
}

export interface AddUserToPartnerSuccessAction {
  type: typeof ADD_USER_TO_PARTNER_SUCCESS
  partnerId: Partner['id']
  userId: Role['userId']
}

export interface AddUserToPartnerFailureAction {
  type: typeof ADD_USER_TO_PARTNER_FAILURE
  payload: Error
}

export interface AddUserToPartnerModalAction {
  type: typeof ADD_USER_TO_PARTNER_MODAL
  payload: boolean
}

export interface SelectPartnerAction {
  type: typeof SELECT_PARTNER
  partner: Partner | undefined
}

export interface ResetPartnerAction {
  type: typeof RESET_PARTNER
  partner: undefined
}

export interface UpdateRoleEntitlementsRequestAction {
  type: typeof UPDATE_ROLE_ENTITLEMENTS_REQUEST
}

export interface UpdateRoleEntitlementsSuccessAction {
  type: typeof UPDATE_ROLE_ENTITLEMENTS_SUCCESS
  partnerId: Partner['id']
  userId: Role['userId']
  entitlements: Entitlements[]
}

export interface UpdateRoleEntitlementsFailureAction {
  type: typeof UPDATE_ROLE_ENTITLEMENTS_FAILURE
  error: Error
}

export interface AddPartnerModalOpenAction {
  type: typeof ADD_PARTNER_MODAL_OPEN
  open: boolean
}

export interface UpdatePartnerModalOpenAction {
  type: typeof UPDATE_PARTNER_MODAL_OPEN
  open: boolean
}

export interface SuspendPartnerRequestAction {
  type: typeof SUSPEND_PARTNER_REQUEST
  partner: Partner
}

export interface SuspendPartnerSuccessAction {
  type: typeof SUSPEND_PARTNER_SUCCESS
  partner: Partner
}

export interface SuspendPartnerFailureAction {
  type: typeof SUSPEND_PARTNER_FAILURE
  error: Error
}

export interface UnsuspendPartnerRequestAction {
  type: typeof UNSUSPEND_PARTNER_REQUEST
  partner: Partner
}

export interface UnsuspendPartnerSuccessAction {
  type: typeof UNSUSPEND_PARTNER_SUCCESS
  partner: Partner
}

export interface UnsuspendPartnerFailureAction {
  type: typeof UNSUSPEND_PARTNER_FAILURE
  error: Error
}

export interface UpdatePartnerRequestAction {
  type: typeof UPDATE_PARTNER_REQUEST
}

export interface UpdatePartnerSuccessAction {
  type: typeof UPDATE_PARTNER_SUCCESS
}

export interface UpdatePartnerFailureAction {
  type: typeof UPDATE_PARTNER_FAILURE
  error: Error
}

export interface SetPartnerToSuspendAction {
  type: typeof SET_PARTNER_TO_SUSPEND
  partner: Partner | undefined
}

export interface SuspendRoleRequestAction {
  type: typeof SUSPEND_ROLE_REQUEST
  userId: string
  partnerId: string
}

export interface SuspendRoleSuccessAction {
  type: typeof SUSPEND_ROLE_SUCCESS
  userId: string
  partnerId: string
}

export interface SuspendRoleFailureAction {
  type: typeof SUSPEND_ROLE_FAILURE
  userId: string
  partnerId: string
}

export interface UnsuspendRoleRequestAction {
  type: typeof UNSUSPEND_ROLE_REQUEST
  userId: string
  partnerId: string
}

export interface UnsuspendRoleSuccessAction {
  type: typeof UNSUSPEND_ROLE_SUCCESS
  userId: string
  partnerId: string
}

export interface UnsuspendRoleFailureAction {
  type: typeof UNSUSPEND_ROLE_FAILURE
  userId: string
  partnerId: string
}

export interface SuspendUserFromAllPartnersRequestAction {
  type: typeof SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST
  userId: string
  partners: Partner[]
}

export interface SuspendUserFromAllPartnersSuccessAction {
  type: typeof SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS
  userId: string
}

export interface SuspendUserFromAllPartnersFailureAction {
  type: typeof SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE
  userId: string
}

export interface RemoveRoleFromPartnerRequestAction {
  type: typeof REMOVE_ROLE_FROM_PARTNER_REQUEST
}
export interface RemoveRoleFromPartnerSuccessAction {
  type: typeof REMOVE_ROLE_FROM_PARTNER_SUCCESS
  partnerId: Partner['id']
  userId: User['id']
}

export interface RemoveRoleFromPartnerFailureAction {
  type: typeof REMOVE_ROLE_FROM_PARTNER_FAILURE
  error: Error
}

export interface RemoveUserFromAllPartnersRequestAction {
  type: typeof REMOVE_USER_FROM_ALL_PARTNERS_REQUEST
}

export interface RemoveUserFromAllPartnersSuccessAction {
  type: typeof REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS
  partnerIds: Partner['id'][]
  user: User
}

export interface RemoveUserFromAllPartnersFailureAction {
  type: typeof REMOVE_USER_FROM_ALL_PARTNERS_FAILURE
}

export type PartnerActionTypes =
  | FetchPartnersRequestAction
  | FetchPartnersSuccessAction
  | FetchPartnersFailureAction
  | FetchPartnerRequestAction
  | FetchPartnerSuccessAction
  | FetchPartnerFailureAction
  | AddUserToPartnerRequestAction
  | AddUserToPartnerSuccessAction
  | AddUserToPartnerFailureAction
  | AddUserToPartnerModalAction
  | SelectPartnerAction
  | ResetPartnerAction
  | UpdateRoleEntitlementsRequestAction
  | UpdateRoleEntitlementsSuccessAction
  | UpdateRoleEntitlementsFailureAction
  | AddPartnerModalOpenAction
  | UpdatePartnerModalOpenAction
  | SuspendPartnerRequestAction
  | SuspendPartnerSuccessAction
  | SuspendPartnerFailureAction
  | UnsuspendPartnerRequestAction
  | UnsuspendPartnerSuccessAction
  | UnsuspendPartnerFailureAction
  | UpdatePartnerRequestAction
  | UpdatePartnerSuccessAction
  | UpdatePartnerFailureAction
  | SetPartnerToSuspendAction
  | SuspendRoleRequestAction
  | SuspendRoleSuccessAction
  | SuspendRoleFailureAction
  | UnsuspendRoleRequestAction
  | UnsuspendRoleSuccessAction
  | UnsuspendRoleFailureAction
  | SuspendUserFromAllPartnersRequestAction
  | SuspendUserFromAllPartnersSuccessAction
  | SuspendUserFromAllPartnersFailureAction
  | FetchRoleFailureAction
  | FetchRoleRequestAction
  | FetchRoleSuccessAction
  | RemoveRoleFromPartnerRequestAction
  | RemoveRoleFromPartnerSuccessAction
  | RemoveRoleFromPartnerFailureAction
  | RemoveUserFromAllPartnersRequestAction
  | RemoveUserFromAllPartnersSuccessAction
  | RemoveUserFromAllPartnersFailureAction
