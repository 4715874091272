import {
  AuthenticationResult,
  Configuration,
  EventType,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser'
import { getConfig } from './utils'

const APP_CONFIG = getConfig()

export const loginRequest: RedirectRequest = {
  scopes: APP_CONFIG.b2c.scopes,
}

const msalConfig: Configuration = {
  auth: {
    authority: `${APP_CONFIG.b2c.authority}/${APP_CONFIG.b2c.policies.signUpSignIn}`,
    clientId: APP_CONFIG.b2c.clientId,
    knownAuthorities: APP_CONFIG.b2c.knownAuthorities,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}

const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback(async (callback) => {
  switch (callback.eventType) {
    case EventType.LOGIN_FAILURE:
    case EventType.ACQUIRE_TOKEN_FAILURE:
      // If user cancelled input we do nothing
      if (callback.error?.message.includes('AADB2C90091')) {
        break
      }
      msalInstance.logoutRedirect()
      break
    case EventType.ACQUIRE_TOKEN_SUCCESS:
    case EventType.LOGIN_SUCCESS:
      {
        const payload = callback.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
      }
      break
  }
})

/* 
Calling .handleRedirectPromise is supposed to not be necessary
when using msal-react, but the way we handle editProfile and forgotPassword
necessitates it.

Because of this, when a user cancel any of those flows, we need to handle
*/

msalInstance
  .handleRedirectPromise()
  .then((authResult) => {
    const account = authResult?.account
    if (account) {
      msalInstance.setActiveAccount(account)
      msalInstance.getAllAccounts().forEach((acc) => {
        if (acc.homeAccountId !== account?.homeAccountId) {
          msalInstance.logoutRedirect({
            account: acc,
            onRedirectNavigate: () => false,
          })
        }
      })
    }
  })
  .catch((error) => null /* Handle in addEventCallback */)

export async function getAccessToken() {
  const request: SilentRequest = {
    ...loginRequest,
    redirectUri: '/blank',
  }
  const authResponse = await msalInstance.acquireTokenSilent(request)
  return authResponse.accessToken
}

export default msalInstance
