import { Dialog, Grid, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react'
import Logo from '../Logo/Logo'
import { useAuth } from '../../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { InteractionType } from '@azure/msal-browser'

export const Redeemer: FC = () => {
  const { getInviteSignUpRequest } = useAuth()
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(search)
  const inviteToken = urlSearchParams.get('inviteToken')
  const lang = urlSearchParams.get('lang') ?? undefined

  useEffect(() => {
    if (isAuthenticated) {
      instance.logoutRedirect({
        postLogoutRedirectUri: `/redeem${search}`,
      })
    }
  }, [isAuthenticated, instance, search])

  return inviteToken ? (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={getInviteSignUpRequest(inviteToken, lang)}
      loadingComponent={LoadingComponent}
      errorComponent={Redeemer}
    ></MsalAuthenticationTemplate>
  ) : (
    <h2>No invite token provided</h2>
  )
}

const LoadingComponent: FC = () => (
  <Dialog open={true} fullScreen>
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      style={{ height: '100%' }}
      spacing={1}
    >
      <Grid item style={{ marginBottom: '2em' }}>
        <Logo />
      </Grid>
      <Grid item>
        <Typography variant='h4'>Redirecting to login service...</Typography>
      </Grid>
    </Grid>
  </Dialog>
)
