import { EMPTY_APPLICATION_PERMISSION_SET } from '../../shared/model/Permission'

import {
  createPartnerFailure,
  createPartnerRequest,
  createPartnerSuccess,
} from './createPartner/reducers'
import {
  fetchPermissionsFailure,
  fetchPermissionsRequest,
  fetchPermissionsSuccess,
} from './fetchPermissions/reducers'
import {
  fetchSalesDivisionsFailure,
  fetchSalesDivisionsRequest,
  fetchSalesDivisionsSuccess,
} from './fetchSalesDivisions/reducers'
import {
  ApplicationsState,
  ApplicationsActionTypes,
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
  FETCH_SALES_DIVISIONS_REQUEST,
  FETCH_SALES_DIVISIONS_SUCCESS,
  FETCH_SALES_DIVISIONS_FAILURE,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAILURE,
  CREATE_PARTNER_REQUEST,
} from './types'

export const initialState: ApplicationsState = {
  error: undefined,
  loading: false,
  salesDivisions: [],
  loadingSalesDivisions: false,
  salesDivisionsFetched: false,
  permissions: EMPTY_APPLICATION_PERMISSION_SET,
  loadingPermissions: false,
  creatingGroupLoading: false,
  createGroupError: undefined,
  createPartnerError: undefined,
  creatingPartnerLoading: false,
}

export function applicationsReducer(
  state = initialState,
  action: ApplicationsActionTypes,
): ApplicationsState {
  switch (action.type) {
    case FETCH_PERMISSIONS_REQUEST:
      return fetchPermissionsRequest(state, action)
    case FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(state, action)
    case FETCH_PERMISSIONS_FAILURE:
      return fetchPermissionsFailure(state, action)

    case FETCH_SALES_DIVISIONS_REQUEST:
      return fetchSalesDivisionsRequest(state, action)
    case FETCH_SALES_DIVISIONS_SUCCESS:
      return fetchSalesDivisionsSuccess(state, action)
    case FETCH_SALES_DIVISIONS_FAILURE:
      return fetchSalesDivisionsFailure(state, action)

    case CREATE_PARTNER_REQUEST:
      return createPartnerRequest(state, action)
    case CREATE_PARTNER_SUCCESS:
      return createPartnerSuccess(state, action)
    case CREATE_PARTNER_FAILURE:
      return createPartnerFailure(state, action)

    default:
      return state
  }
}
