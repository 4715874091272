import {
  PartnersState,
  SuspendUserFromAllPartnersFailureAction,
  SuspendUserFromAllPartnersRequestAction,
  SuspendUserFromAllPartnersSuccessAction,
} from '../types'

export const suspendUserFromAllPartnersRequest = (
  state: PartnersState,
  action: SuspendUserFromAllPartnersRequestAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: true,
    },
  }
}

export const suspendUserFromAllPartnersSuccess = (
  state: PartnersState,
  action: SuspendUserFromAllPartnersSuccessAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: false,
    },
  }
}

export const suspendUserFromAllPartnersFailure = (
  state: PartnersState,
  action: SuspendUserFromAllPartnersFailureAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleSuspendLoading: false,
    },
  }
}
