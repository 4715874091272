import {
  ApplicationsState,
  FetchSalesDivisionsFailureAction,
  FetchSalesDivisionsRequestAction,
  FetchSalesDivisionsSuccessAction,
} from '../types'

export const fetchSalesDivisionsRequest = (
  state: ApplicationsState,
  action: FetchSalesDivisionsRequestAction,
): ApplicationsState => {
  return {
    ...state,
    loadingSalesDivisions: true,
  }
}

export const fetchSalesDivisionsSuccess = (
  state: ApplicationsState,
  action: FetchSalesDivisionsSuccessAction,
): ApplicationsState => {
  return {
    ...state,
    salesDivisions: action.salesDivisions,
    loadingSalesDivisions: false,
    salesDivisionsFetched: true,
  }
}

export const fetchSalesDivisionsFailure = (
  state: ApplicationsState,
  action: FetchSalesDivisionsFailureAction,
): ApplicationsState => {
  return {
    ...state,
    error: action.error,
    loadingSalesDivisions: false,
    salesDivisionsFetched: false,
  }
}
