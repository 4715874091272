import React from 'react'
import { useTranslation } from 'react-i18next'
import { supportedLanguages } from '../../assets/lang/langSupport'

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const handleLanguageChange = async (value: string) => {
    await i18n.changeLanguage(value)
  }

  return (
    <select onChange={(e) => handleLanguageChange(e.target.value)} value={i18n.language}>
      {supportedLanguages.map((lang) => (
        <option key={lang.code} value={lang.code}>
          {lang.flag} {lang.name}
        </option>
      ))}
    </select>
  )
}

export default LanguageSelector
