import { configureStore } from '@reduxjs/toolkit'
import { applicationsReducer } from './applications/reducers'
import { authReducer } from './auth/reducers'
import { uiReducer } from './ui/reducers'
import { usersReducer } from './users/reducers'
import { partnersReducer } from './partners/reducers'

const reducer = {
  auth: authReducer,
  users: usersReducer,
  applications: applicationsReducer,
  partners: partnersReducer,
  ui: uiReducer,
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
