import { Partner } from '../../../shared/model/Partner'
import {
  FetchRoleFailureAction,
  FetchRoleRequestAction,
  FetchRoleSuccessAction,
  PartnersState,
} from '../types'

//Added an "isFetching"-property ro Role that was member on the memberObject
export const fetchRoleRequest = (
  state: PartnersState,
  action: FetchRoleRequestAction,
): PartnersState => {
  const partners = state.partners
  const partner = partners.find((p) => p.id === action.partnerId)
  const role = partner?.roles?.find((r) => r.userId === action.userId)
  if (!partner || !role) return state

  const newPartner = {
    ...partner,
    roles: partner.roles
      .filter((r) => r.userId !== action.userId)
      .concat({
        ...role,
        isFetching: true,
      }),
  }

  const newPartners: Partner[] = [
    ...partners.filter((p) => p.id !== action.partnerId).concat(newPartner),
  ]

  return {
    ...state,
    partner: newPartner,
    partners: newPartners,
  }
}

export const fetchRoleSuccess = (
  state: PartnersState,
  action: FetchRoleSuccessAction,
): PartnersState => {
  const statePartner = state.partner
  const role = action.payload
  const partnerId = action.payload.partnerId
  const partners = state.partners
  const partner = partners.find((p) => p.id === partnerId)

  if (!partner || !role) return state

  const updatedRolesList = partner.roles.filter((r) => r.userId !== role.userId).concat(role)

  const updatedPartnerList = partners.map((p) =>
    p.id === partnerId ? { ...p, roles: updatedRolesList } : p,
  )

  let newState = {
    ...state,
    partners: updatedPartnerList,
  }

  if (statePartner && statePartner.id === partnerId) {
    newState = {
      ...newState,
      partner: {
        ...partner,
        roles: updatedRolesList,
      },
    }
  }
  return newState
}

//This does nothing since the isFetching property is not in the RoleObject (add isFetching?).
export const fetchRoleFailure = (
  state: PartnersState,
  action: FetchRoleFailureAction,
): PartnersState => {
  const partner = state.partner
  const role = partner?.roles?.find((r) => r.userId === action.userId)
  if (!partner || !role) return state

  const newPartner = {
    ...partner,
    roles: partner.roles.filter((r) => r.userId !== action.userId).concat({ ...role }),
  }

  return {
    ...state,
    partner: newPartner,
  }
}
