import {
  PartnersState,
  SuspendPartnerRequestAction,
  SuspendPartnerSuccessAction,
  SuspendPartnerFailureAction,
} from '../types'

export const suspendPartnerRequest = (
  state: PartnersState,
  action: SuspendPartnerRequestAction,
): PartnersState => {
  return {
    ...state,
    partnerStatusLoading: true,
  }
}

export const suspendPartnerSuccess = (
  state: PartnersState,
  action: SuspendPartnerSuccessAction,
): PartnersState => {
  const statePartners = state.partners
  const partnerId = action.partner.id
  const updatedPartner = { ...action.partner, suspended: true }
  return {
    ...state,
    partnerStatusLoading: false,
    partners: [
      ...statePartners.filter((partner) => partner.id !== partnerId).concat(updatedPartner),
    ],
  }
}

export const suspendPartnerFailure = (
  state: PartnersState,
  action: SuspendPartnerFailureAction,
): PartnersState => {
  return {
    ...state,
    partnerStatusLoading: false,
  }
}
