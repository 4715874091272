import React from 'react'
import thermiaCiamLogo from '../../assets/images/logo-thermia-ciam.svg'
import classes from './Logo.module.css'

const Logo: React.FC = () => (
  <div className={classes.LogoWrapper}>
    <img src={thermiaCiamLogo} className={classes.Image} alt='Thermia IAM' />
  </div>
)

export default Logo
