import { Popover, PopoverProps } from '@mui/material'
import React from 'react'
import LanguageSettings from '../../containers/Settings/LanguageSettings'
import { useTheme } from '@mui/material/styles'

interface ProfileMenuProps {
  id: string
  anchorEl: PopoverProps['anchorEl']
  onClose: PopoverProps['onClose']
}

const LanguageMenu: React.FC<ProfileMenuProps> = ({ id, anchorEl, onClose }) => {
  const theme = useTheme()
  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      marginThreshold={0}
      PaperProps={{
        style: {
          width: 165,
          textAlign: 'center',
          background: `${theme.palette.primary.main}`,
          padding: '10px 0px',
          margin: '0px',
        },
      }}
    >
      <LanguageSettings />
    </Popover>
  )
}

export default LanguageMenu
