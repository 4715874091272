import {
  PartnersState,
  UnsuspendRoleFailureAction,
  UnsuspendRoleRequestAction,
  UnsuspendRoleSuccessAction,
} from '../types'

export const unsuspendRoleRequest = (
  state: PartnersState,
  action: UnsuspendRoleRequestAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleUnsuspendLoading: true,
    },
  }
}

export const unsuspendRoleSuccess = (
  state: PartnersState,
  action: UnsuspendRoleSuccessAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleUnsuspendLoading: false,
    },
  }
}

export const unsuspendRoleFailure = (
  state: PartnersState,
  action: UnsuspendRoleFailureAction,
): PartnersState => {
  return {
    ...state,
    role: {
      ...state.role,
      roleUnsuspendLoading: false,
    },
  }
}
