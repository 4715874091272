import Drawer from '@mui/material/Drawer'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo/Logo'
import List from '@mui/material/List'
import { NavLink, Route } from 'react-router-dom'
import theme from '../../hoc/layout/theme'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import classes from './Sidebar.module.css'
import NewNavigationItem from './NavigationItem/NavigationItem'
import { fetchUsers } from '../../store/users/actions'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { fetchPartners } from '../../store/partners/fetchPartners/actions'
import { Box, ListItem, Skeleton } from '@mui/material'

const drawerWidth = 270

const useStyles = makeStyles(() => ({
  drawerWrapper: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main,
  },
  drawerContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    borderRight: 'none',
  },
}))

interface SidebarProps {
  open: boolean
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
}

const SideBar: React.FC<SidebarProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const selectedPartner = useAppSelector((state) => state.partners.partner)
  const numberOfPartners = useAppSelector((state) => state.partners.partners.length)
  const partnersAreLoading = useAppSelector((state) => state.partners.loadingPartners)
  const numberOfUsers = useAppSelector((state) => state.users.users.length)
  const usersAreLoading = useAppSelector((state) => state.users.usersLoading)
  const isAuthenticated = useIsAuthenticated()
  const msal = useMsal()
  const [hasFetchedPartners, setHasFetchedPartners] = useState(false)
  const [hasFetchedUsers, setHasFetchedUsers] = useState(false)

  const getPartners = useCallback(async () => {
    if (numberOfPartners === 0 && !partnersAreLoading && !hasFetchedPartners) {
      setHasFetchedPartners(true)
      await dispatch(fetchPartners())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, numberOfPartners, partnersAreLoading])

  useEffect(() => {
    if (!isAuthenticated || msal.inProgress !== 'none') return
    getPartners()
  }, [getPartners, isAuthenticated, msal.inProgress])

  useEffect(() => {
    if (!isAuthenticated || msal.inProgress !== 'none') return
    if (numberOfUsers === 0 && !usersAreLoading && !hasFetchedUsers) {
      setHasFetchedUsers(true)
      dispatch(fetchUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, numberOfUsers, usersAreLoading, isAuthenticated, msal.inProgress])

  const drawerContent = (
    <div className={styles.drawerContent}>
      <Logo />
      <nav className={classes.NavigationList}>
        <List sx={{ height: '415px' }}>
          {partnersAreLoading ? (
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 0px',
                marginTop: '-20px',
                minHeight: '55px',
              }}
            >
              <Skeleton
                height={90}
                width={drawerWidth - 40}
                component='div'
                sx={{ borderRadius: '4px' }}
              />
            </ListItem>
          ) : (
            <>
              <Box
                sx={{
                  minHeight: '70px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {selectedPartner && (
                  <Route path='/group/:groupId'>
                    <NavLink
                      to={`/group/${selectedPartner?.id}`}
                      exact
                      className={classes.Link}
                      activeClassName={classes.Active}
                      style={{ marginTop: 'auto' }}
                    >
                      <NewNavigationItem
                        label={t('My Corporation')}
                        sublabel={selectedPartner?.name}
                        iconName='home'
                      />
                    </NavLink>
                  </Route>
                )}
              </Box>
              {numberOfPartners > 1 && (
                <NavLink
                  to='/group?no-redirect'
                  exact
                  className={classes.Link}
                  activeClassName={classes.Active}
                >
                  <NewNavigationItem
                    label={t('Groups')}
                    iconName='domain'
                    rightAlignedText={numberOfPartners ? numberOfPartners.toString() : ''}
                    loading={partnersAreLoading}
                  />
                </NavLink>
              )}

              {numberOfPartners > 1 && (
                <NavLink
                  to='/users'
                  exact
                  className={classes.Link}
                  activeClassName={classes.Active}
                >
                  <NewNavigationItem
                    label={t('Users')}
                    iconName='groups'
                    rightAlignedText={numberOfUsers ? numberOfUsers.toString() : ''}
                    loading={usersAreLoading}
                  />
                </NavLink>
              )}
            </>
          )}
        </List>
      </nav>
    </div>
  )

  return (
    <>
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
          },
        }}
        variant='temporary'
        open={open}
        onClose={onClose}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          flexBasis: drawerWidth,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
          },
        }}
        variant='permanent'
        open
      >
        {drawerContent}
      </Drawer>
    </>
  )
}

export default SideBar
