import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { removeToaster } from '../../../store/ui/actions'

const Toaster: React.FC = () => {
  const dispatch = useAppDispatch()
  const toast = useAppSelector((state) => state.ui.toasterMessage)
  const handleClose = () => {
    dispatch(removeToaster())
  }
  const autoHideDuration = 3000
  const toaster = toast ? (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={true}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <MuiAlert severity={toast.severity} variant='filled' onClose={handleClose}>
        {toast.message}
      </MuiAlert>
    </Snackbar>
  ) : null

  return toaster
}

export default Toaster
