import retry from 'async-retry'
import axiosInstance from '../../../axios'
import { Partner, Role } from '../../../shared/model/Partner'
import { AppThunk } from '../../utils'
import {
  PartnerActionTypes,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAILURE,
} from '../types'

export function fetchRoleRequest(
  userId: Role['userId'],
  partnerId: Role['partnerId'],
): PartnerActionTypes {
  return {
    type: FETCH_ROLE_REQUEST,
    partnerId,
    userId,
  }
}

export function fetchRoleSuccess(role: Role): PartnerActionTypes {
  return {
    type: FETCH_ROLE_SUCCESS,
    payload: role,
  }
}

export function fetchRoleFailure(
  userId: Role['userId'],
  partnerId: Role['partnerId'],
): PartnerActionTypes {
  return {
    type: FETCH_ROLE_FAILURE,
    userId: userId,
    partnerId: partnerId,
  }
}

export function fetchRoleWithRetry(
  partnerId: Partner['id'],
  role: Role,
  options: {
    retryCondition: (role: Role) => boolean
  } & retry.Options,
): AppThunk<Promise<Role | undefined>> {
  return async (dispatch) => {
    dispatch(fetchRoleRequest(role.userId, partnerId))
    try {
      return await retry(async (bail) => {
        const newRole = await getRole(partnerId, role)
        if (options.retryCondition(newRole)) {
          throw new Error('Role has not been suspended, retry')
        }
        dispatch(fetchRoleSuccess(newRole))
        return newRole
      }, options)
    } catch (error) {
      dispatch(fetchRoleFailure(role.userId, partnerId))
    }
  }
}

async function getRole(partnerId: string, role: Role): Promise<Role> {
  const { data: newRole } = await axiosInstance.get<Role>(
    `role/roles/partners/${partnerId}/users/${role.userId}`,
  )
  return newRole
}
