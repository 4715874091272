import { Partner, Role } from '../../../shared/model/Partner'
import {
  PartnersState,
  AddUserToPartnerRequestAction,
  AddUserToPartnerSuccessAction,
  AddUserToPartnerFailureAction,
} from '../types'

export const addUserToPartnerRequest = (
  state: PartnersState,
  action: AddUserToPartnerRequestAction,
): PartnersState => {
  return {
    ...state,
    addUserToPartnerLoading: true,
  }
}

export const addUserToPartnerSuccess = (
  state: PartnersState,
  action: AddUserToPartnerSuccessAction,
): PartnersState => {
  return {
    ...state,
    addUserToPartnerLoading: false,
    ...addUserToPartnerLocally(state, action),
  }
}

export const addUserToPartnerFailure = (
  state: PartnersState,
  action: AddUserToPartnerFailureAction,
): PartnersState => {
  return {
    ...state,
    addUserToPartnerError: action.payload,
    addUserToPartnerLoading: false,
  }
}

// This function could be used for optimistic updates in the future
const addUserToPartnerLocally = (
  state: PartnersState,
  action: AddUserToPartnerSuccessAction,
): Partial<PartnersState> => {
  const currentPartner =
    state.partner?.id === action.partnerId
      ? state.partner
      : state.partners.find((g) => g.id === action.partnerId)
  if (!currentPartner) {
    return {}
  }
  const newRole: Role = {
    userId: action.userId,
    partnerId: action.partnerId,
    entitlements: [],
    attributes: {},
  }

  const newPartner: Partner = {
    ...currentPartner,
    roles: [...currentPartner.roles.filter((r) => r.userId !== action.userId), newRole],
  }
  return {
    partner: newPartner,
    partners: [...state.partners.filter((p) => p.id !== newPartner.id), newPartner],
  }
}
