import {
  PartnersState,
  FetchPartnersSuccessAction,
  FetchPartnersFailureAction,
  FetchPartnersRequestAction,
} from '../types'

export const fetchPartnersRequest = (
  state: PartnersState,
  action: FetchPartnersRequestAction,
): PartnersState => {
  return {
    ...state,
    loadingPartners: !action.isRefresh,
    refreshingPartners: action.isRefresh,
  }
}

export const fetchPartnersSuccess = (
  state: PartnersState,
  action: FetchPartnersSuccessAction,
): PartnersState => {
  let partner
  if (state.partner) {
    partner = action.payload.find((currentPartner) => currentPartner.id === state.partner?.id)
  }
  return {
    ...state,
    partnersFetched: true,
    partners: action.payload,
    loadingPartners: false,
    refreshingPartners: false,
    partner,
  }
}

export const fetchPartnersFailure = (
  state: PartnersState,
  action: FetchPartnersFailureAction,
): PartnersState => {
  return {
    ...state,
    error: action.payload,
    loadingPartners: false,
    refreshingPartners: false,
    partnersFetched: false,
  }
}
