import { ToasterMessage } from '../../shared/model/Utils'
import { ADD_TOASTER, REMOVE_TOASTER, UIActionTypes } from './types'

export function addToaster(
  message: ToasterMessage['message'],
  severity: ToasterMessage['severity'],
): UIActionTypes {
  return {
    type: ADD_TOASTER,
    payload: {
      message,
      severity,
    },
  }
}

export function removeToaster(): UIActionTypes {
  return {
    type: REMOVE_TOASTER,
  }
}
