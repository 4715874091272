import axiosInstance from '../../../axios'
import { getRole, isRoleSuspended } from '../../../components/User/Utils'
import { APPLICATIONS } from '../../../shared/model/Application'
import { Partner, Role } from '../../../shared/model/Partner'
import { ToastSeverity } from '../../../shared/model/Utils'
import { addToaster } from '../../ui/actions'
import { AppThunk } from '../../utils'
import { fetchRoleWithRetry } from '../fetchRole/actions'
import {
  PartnerActionTypes,
  SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE,
  SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST,
  SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS,
} from '../types'

export function suspendUserFromAllPartnersRequest(
  userId: string,
  partners: Partner[],
): PartnerActionTypes {
  return {
    type: SUSPEND_USER_FROM_ALL_PARTNERS_REQUEST,
    userId,
    partners,
  }
}

export function suspendUserFromAllPartnersSuccess(userId: string): PartnerActionTypes {
  return {
    type: SUSPEND_USER_FROM_ALL_PARTNERS_SUCCESS,
    userId,
  }
}

export function suspendUserFromAllPartnersFailure(userId: string): PartnerActionTypes {
  return {
    type: SUSPEND_USER_FROM_ALL_PARTNERS_FAILURE,
    userId,
  }
}

export const suspendUserFromAllPartners =
  (partners: Partner[], userId: string): AppThunk<Promise<any>> =>
  async (dispatch) => {
    dispatch(suspendUserFromAllPartnersRequest(userId, partners))
    type RoleObject = { partnerId: Partner['id']; role: Role }
    const successfullIds: RoleObject[] = []
    const failedIds: Partner['id'][] = []

    for (var partner of partners) {
      try {
        const role = getRole(userId, partner)
        if (role === undefined) throw new Error()

        //Funkar detta på samma sätt nu som med member?
        if (isRoleSuspended(role)) continue

        const resp = await axiosInstance.post(
          `role/roles/partners/${partner.id}/users/${userId}/actions/suspend`,
        )

        if (resp.status !== 204) {
          failedIds.push(partner.id)
          continue
        }

        successfullIds.push({ partnerId: partner.id, role: role })
      } catch (error) {
        failedIds.push(partner.id)
      }
    }

    if (failedIds.length !== 0) {
      dispatch(suspendUserFromAllPartnersFailure(userId))
      dispatch(addToaster('Member suspend failed', ToastSeverity.ERROR))
      return
    }

    for (var success of successfullIds) {
      dispatch(
        fetchRoleWithRetry(success.partnerId, success.role, {
          retryCondition: (r) => {
            return APPLICATIONS.some((app) => {
              const entitlements = r.entitlements.find((ent) => ent.application === app.id)
              return entitlements?.permissions && !app.suspendedRule(entitlements.permissions)
            })
          },
          retries: 5,
          minTimeout: 500,
        }),
      )
    }

    dispatch(suspendUserFromAllPartnersSuccess(userId))
    dispatch(addToaster('Member suspended', ToastSeverity.SUCCESS))
  }
