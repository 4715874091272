import { Partner } from '../../shared/model/Partner'
import { User, UserDialog, UserDialogAction } from '../../shared/model/User'
import { Error } from '../../shared/model/Utils'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'

export const LOGIN_AS_USER_REQUEST = 'LOGIN_AS_USER_REQUEST'
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS'
export const LOGIN_AS_USER_FAILURE = 'LOGIN_AS_USER_FAILURE'

export const SET_USER_DIALOG = 'SET_USER_DIALOG'
export const CLOSE_USER_DIALOG = 'CLOSE_USER_DIALOG'

export const ADD_USER_MODAL_OPEN = 'ADD_USER_MODAL_OPEN'
export const CHANGE_USER_MODAL_OPEN = 'CHANGE_USER_MODAL_OPEN'
export const VIEW_USER_MODAL_OPEN = 'VIEW_USER_MODAL_OPEN'
export const EDITING_USER_PERMISSION = 'EDITING_USER_PERMISSION'
export const USER_INVITED_SUCCESS = 'USER_INVITED_SUCCESS'

export const RESEND_USER_INVITE_REQUEST = 'RESEND_USER_INVITE_REQUEST'
export const RESEND_USER_INVITE_SUCCESS = 'RESEND_USER_INVITE_SUCCESS'
export const RESEND_USER_INVITE_FAILURE = 'RESEND_USER_INVITE_FAILURE'

export const REMOVE_USER_FROM_ALL_GROUPS_REQUEST = 'REMOVE_USER_FROM_ALL_GROUPS_REQUEST'
export const REMOVE_USER_FROM_ALL_GROUPS_SUCCESS = 'REMOVE_USER_FROM_ALL_GROUPS_SUCCESS'
export const REMOVE_USER_FROM_ALL_GROUPS_FAILURE = 'REMOVE_USER_FROM_ALL_GROUPS_FAILURE'

export const REMOVE_USER_FROM_ALL_PARTNERS_REQUEST = 'REMOVE_USER_FROM_ALL_PARTNERS_REQUEST'
export const REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS = 'REMOVE_USER_FROM_ALL_PARTNERS_SUCCESS'
export const REMOVE_USER_FROM_ALL_PARTNERS_FAILURE = 'REMOVE_USER_FROM_ALL_PARTNERS_FAILURE'

export interface UsersState {
  user: User | undefined
  users: User[]
  error: Error | undefined
  usersLoading: boolean
  addUserModalOpen: boolean
  userDialog: UserDialog | undefined
  changeUserModalOpen: boolean
  viewUserModalOpen: boolean
  editingUserPermissions: boolean
  resendUserInviteLoading: {
    userId?: string
    loading: boolean
  }
  loginAs: { loading: boolean; error?: Error }
}

export interface FetchUsersRequestAction {
  type: typeof FETCH_USERS_REQUEST
}

export interface FetchUsersSuccessAction {
  type: typeof FETCH_USERS_SUCCESS
  payload: User[]
}

export interface FetchUsersFailureAction {
  type: typeof FETCH_USERS_FAILURE
  payload: Error
}

export interface RemoveUserRequestAction {
  type: typeof REMOVE_USER_REQUEST
}

export interface RemoveUserSuccessAction {
  type: typeof REMOVE_USER_SUCCESS
  user: User
}

export interface RemoveUserFailureAction {
  type: typeof REMOVE_USER_FAILURE
  error: Error
}

export interface LoginAsUserRequestAction {
  type: typeof LOGIN_AS_USER_REQUEST
  user: User
}
export interface LoginAsUserSuccessAction {
  type: typeof LOGIN_AS_USER_SUCCESS
}
export interface LoginAsUserFailureAction {
  type: typeof LOGIN_AS_USER_FAILURE
  error: Error
}

export interface SetUserDialogAction {
  type: typeof SET_USER_DIALOG
  user: User
  action: UserDialogAction
  partner?: Partner
  partners?: Partner[]
}

export interface CloseUserDialogAction {
  type: typeof CLOSE_USER_DIALOG
}

export interface AddUserModalOpenAction {
  type: typeof ADD_USER_MODAL_OPEN
  open: boolean
}

export interface ChangeUserModalOpenAction {
  type: typeof CHANGE_USER_MODAL_OPEN
  open: boolean
}

export interface ViewUserModalOpenAction {
  type: typeof VIEW_USER_MODAL_OPEN
  open: boolean
}

export interface EditingUserPermissionsAction {
  type: typeof EDITING_USER_PERMISSION
  status: boolean
}

export interface UserInvitedSuccessAction {
  type: typeof USER_INVITED_SUCCESS
  user: User
}

export interface ResendUserInviteRequestAction {
  type: typeof RESEND_USER_INVITE_REQUEST
  userId: User['id']
}

export interface ResendUserInviteSuccessAction {
  type: typeof RESEND_USER_INVITE_SUCCESS
}

export interface ResendUserInviteFailureAction {
  type: typeof RESEND_USER_INVITE_FAILURE
  error?: Error
}

export type UsersActionTypes =
  | FetchUsersRequestAction
  | FetchUsersSuccessAction
  | FetchUsersFailureAction
  | LoginAsUserRequestAction
  | LoginAsUserSuccessAction
  | LoginAsUserFailureAction
  | SetUserDialogAction
  | CloseUserDialogAction
  | AddUserModalOpenAction
  | RemoveUserRequestAction
  | RemoveUserSuccessAction
  | RemoveUserFailureAction
  | ChangeUserModalOpenAction
  | ViewUserModalOpenAction
  | EditingUserPermissionsAction
  | UserInvitedSuccessAction
  | ResendUserInviteRequestAction
  | ResendUserInviteSuccessAction
  | ResendUserInviteFailureAction
