import { Partner } from '../../../shared/model/Partner'
import { AppThunk } from '../../utils'
import { PartnerService } from '../PartnerService'
import {
  PartnerActionTypes,
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
} from '../types'

export function fetchPartnersRequest(options?: { refresh?: boolean }): PartnerActionTypes {
  return {
    type: FETCH_PARTNERS_REQUEST,
    isRefresh: !!options?.refresh,
  }
}

export function fetchPartnersSuccess(partners: Partner[]): PartnerActionTypes {
  return {
    type: FETCH_PARTNERS_SUCCESS,
    payload: partners,
  }
}

export function fetchPartnersFailure(error: Error): PartnerActionTypes {
  return {
    type: FETCH_PARTNERS_FAILURE,
    payload: error,
  }
}

export const fetchPartners = (): AppThunk<Promise<Partner[]>> => async (dispatch, getState) => {
  let shouldRefresh = false
  const {
    partners: { partners, loadingPartners },
  } = getState()
  if (loadingPartners) return partners
  if (partners && partners.length > 0) {
    shouldRefresh = true
  }
  dispatch(fetchPartnersRequest({ refresh: shouldRefresh }))

  try {
    const partners = await PartnerService.getPartners()
    dispatch(fetchPartnersSuccess(partners))
    return partners
  } catch (error: any) {
    dispatch(fetchPartnersFailure(error))
    return []
  }
}
