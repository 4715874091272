import {
  LoginAsUserFailureAction,
  LoginAsUserRequestAction,
  LoginAsUserSuccessAction,
  UsersState,
} from '../types'

export const loginAsUserRequest = (
  state: UsersState,
  action: LoginAsUserRequestAction,
): UsersState => {
  return {
    ...state,
    loginAs: {
      loading: true,
    },
  }
}

export const loginAsUserSuccess = (
  state: UsersState,
  action: LoginAsUserSuccessAction,
): UsersState => {
  return {
    ...state,
    loginAs: {
      loading: false,
    },
  }
}

export const loginAsUserFailure = (
  state: UsersState,
  action: LoginAsUserFailureAction,
): UsersState => {
  return {
    ...state,
    loginAs: {
      loading: false,
      error: action.error,
    },
  }
}
