import { UserDialog } from '../../shared/model/User'
import { fetchUsersFailure, fetchUsersRequest, fetchUsersSuccess } from './fetchUsers/reducers'
import { inviteUserSuccess } from './inviteUser/reducers'
import { loginAsUserSuccess, loginAsUserFailure, loginAsUserRequest } from './loginAsUser/reducers'
import { removeUserFailure, removeUserRequest, removeUserSuccess } from './removeUser/reducers'
import {
  resendUserInviteFailure,
  resendUserInviteRequest,
  resendUserInviteSuccess,
} from './resendUserInvite/reducers'
import {
  UsersActionTypes,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  UsersState,
  ADD_USER_MODAL_OPEN,
  AddUserModalOpenAction,
  SetUserDialogAction,
  CLOSE_USER_DIALOG,
  CloseUserDialogAction,
  CHANGE_USER_MODAL_OPEN,
  ChangeUserModalOpenAction,
  ViewUserModalOpenAction,
  VIEW_USER_MODAL_OPEN,
  LOGIN_AS_USER_REQUEST,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_AS_USER_FAILURE,
  EDITING_USER_PERMISSION,
  EditingUserPermissionsAction,
  USER_INVITED_SUCCESS,
  RESEND_USER_INVITE_REQUEST,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_FAILURE,
  SET_USER_DIALOG,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAILURE,
  REMOVE_USER_REQUEST,
} from './types'

export const initialState: UsersState = {
  user: undefined,
  users: [],
  error: undefined,
  usersLoading: false,
  addUserModalOpen: false,
  userDialog: undefined,
  changeUserModalOpen: false,
  viewUserModalOpen: false,
  editingUserPermissions: false,
  resendUserInviteLoading: {
    userId: undefined,
    loading: false,
  },
  loginAs: {
    loading: false,
    error: undefined,
  },
}

const setUserDialog = (state: UsersState, action: SetUserDialogAction): UsersState => {
  const userDialog: UserDialog = {
    user: action.user,
    action: action.action,
    executingAction: false,
    partner: action.partner,
    partners: action.partners,
  }
  return {
    ...state,
    userDialog,
  }
}

const closeUserDialog = (state: UsersState, action: CloseUserDialogAction): UsersState => {
  return {
    ...state,
    userDialog: undefined,
  }
}

const addUserModalOpen = (state: UsersState, action: AddUserModalOpenAction): UsersState => {
  return {
    ...state,
    addUserModalOpen: action.open,
  }
}

const changeUserModalOpen = (state: UsersState, action: ChangeUserModalOpenAction): UsersState => {
  return {
    ...state,
    changeUserModalOpen: action.open,
  }
}

const viewUserModalOpen = (state: UsersState, action: ViewUserModalOpenAction): UsersState => {
  return {
    ...state,
    viewUserModalOpen: action.open,
  }
}

const editingUserPermissions = (state: UsersState, action: EditingUserPermissionsAction) => {
  return {
    ...state,
    editingUserPermissions: action.status,
  }
}

export function usersReducer(state = initialState, action: UsersActionTypes): UsersState {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return fetchUsersRequest(state, action)
    case FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action)
    case FETCH_USERS_FAILURE:
      return fetchUsersFailure(state, action)

    case LOGIN_AS_USER_REQUEST:
      return loginAsUserRequest(state, action)
    case LOGIN_AS_USER_SUCCESS:
      return loginAsUserSuccess(state, action)
    case LOGIN_AS_USER_FAILURE:
      return loginAsUserFailure(state, action)

    case REMOVE_USER_REQUEST:
      return removeUserRequest(state, action)
    case REMOVE_USER_SUCCESS:
      return removeUserSuccess(state, action)
    case REMOVE_USER_FAILURE:
      return removeUserFailure(state, action)

    case RESEND_USER_INVITE_REQUEST:
      return resendUserInviteRequest(state, action)
    case RESEND_USER_INVITE_SUCCESS:
      return resendUserInviteSuccess(state, action)
    case RESEND_USER_INVITE_FAILURE:
      return resendUserInviteFailure(state, action)

    case SET_USER_DIALOG:
      return setUserDialog(state, action)
    case CLOSE_USER_DIALOG:
      return closeUserDialog(state, action)

    case ADD_USER_MODAL_OPEN:
      return addUserModalOpen(state, action)

    case CHANGE_USER_MODAL_OPEN:
      return changeUserModalOpen(state, action)

    case VIEW_USER_MODAL_OPEN:
      return viewUserModalOpen(state, action)

    case EDITING_USER_PERMISSION:
      return editingUserPermissions(state, action)

    case USER_INVITED_SUCCESS:
      return inviteUserSuccess(state, action)

    default:
      return state
  }
}
