export type SupportedLanguage = (typeof supportedLanguages)[number]

export const supportedLanguages = [
  { name: 'Svenska', code: 'sv', flag: '🇸🇪' },
  { name: 'English', code: 'en', flag: '🇬🇧' },
  { name: 'Česky', code: 'cs', flag: '🇨🇿' },
  { name: 'Deutsch', code: 'de', flag: '🇩🇪' },
  { name: 'Español', code: 'es', flag: '🇪🇸' },
  { name: 'Eesti', code: 'et', flag: '🇪🇪' },
  { name: 'Français', code: 'fr', flag: '🇫🇷' },
  { name: 'Italiano', code: 'it', flag: '🇮🇹' },
  { name: 'Nederlands', code: 'nl', flag: '🇳🇱' },
  { name: 'Polski', code: 'pl', flag: '🇵🇱' },
  { name: 'Русский', code: 'ru', flag: '🇷🇺' },
  { name: 'Slovenščina', code: 'sl', flag: '🇸🇮' },
  { name: 'Suomi', code: 'fi', flag: '🇫🇮' },
  { name: 'Norsk', code: 'no', flag: '🇳🇴' },
  { name: 'Dansk', code: 'da', flag: '🇩🇰' },
]
