import React, { Suspense } from 'react'
import { Switch, Redirect, Route, useHistory } from 'react-router-dom'
import InviteRedirect from './components/InviteRedirect/InviteRedirect'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { Redeemer } from './components/Redeemer/Redeemer'
import SuspenseFallback from './components/SuspenseFallback/SuspenseFallback'
import { CustomNavigationClient } from './CustomNavigationClient'
import { useMsal } from '@azure/msal-react'

const AllUsersContainer = React.lazy(() => import('./containers/AllUsers/AllUsers'))
const UsersContainer = React.lazy(() => import('./containers/Partner/Partner'))
const PartnersContainer = React.lazy(() => import('./containers/Partners/Partners'))

const Routes = () => {
  const history = useHistory()
  const { instance } = useMsal()
  const navigationClient = new CustomNavigationClient(history)
  instance.setNavigationClient(navigationClient)

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        <PrivateRoute path='/' exact>
          <Redirect to='/group' />
        </PrivateRoute>

        <PrivateRoute path='/group/:groupId'>
          <UsersContainer />
        </PrivateRoute>

        <PrivateRoute path='/group'>
          <PartnersContainer />
        </PrivateRoute>

        <PrivateRoute path='/users'>
          <AllUsersContainer />
        </PrivateRoute>

        <Route path='/redeem'>
          <Redeemer />
        </Route>

        <Route path='/redirect/invite'>
          <InviteRedirect />
        </Route>

        <Route path='/blank' exact>
          <div>This page is blank</div>
        </Route>
      </Switch>
    </Suspense>
  )
}

export default Routes
