import { UserInvitedSuccessAction, UsersState } from '../types'

export const inviteUserSuccess = (
  state: UsersState,
  action: UserInvitedSuccessAction,
): UsersState => {
  return {
    ...state,
    ...addInvitedUserLocally(state, action),
  }
}

const addInvitedUserLocally = (
  state: UsersState,
  action: UserInvitedSuccessAction,
): Partial<UsersState> => {
  return {
    users: [...state.users.filter((u) => u.id !== action.user.id), action.user],
  }
}
